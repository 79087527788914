import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { MinimalSeller } from 'types/Seller';

type Step = 'form' | 'processing';

const usePayModal = (parentPath: string) => {
  const history = useHistory();
  const seller = useCurrentSeller() as MinimalSeller;
  const companyName = seller.companyName || '';
  const complexAccount = useIsComplexAccount();
  const [step, setStep] = useState<Step>('form');
  const [thirdPartyPaymentId, setThirdPartyPaymentId] = useState<number | null>(
    null
  );

  const close = () => history.replace(parentPath);

  const gotoProcessingStep = (inputThirdPartyPaymentId: number) => {
    setThirdPartyPaymentId(inputThirdPartyPaymentId);
    setStep('processing');
  };

  return {
    close,
    companyName,
    complexAccount,
    gotoProcessingStep,
    step,
    thirdPartyPaymentId,
  };
};

export default usePayModal;

import React, { ReactNode } from 'react';
import { Transition } from '@headlessui/react';

interface Props {
  children: ReactNode;
  in: boolean;
}

const Collapse = ({ children, in: collapsed }: Props) => (
  <Transition
    show={collapsed}
    className="tw-overflow-hidden"
    enter="tw-transition-all tw-ease-in-out tw-duration-[300ms]"
    enterFrom="tw-transform tw-max-h-0"
    enterTo="tw-transform tw-max-h-[1000px]"
    leave="tw-transition-all tw-ease-in-out tw-duration-[300ms]"
    leaveFrom="tw-transform tw-max-h-[1000px]"
    leaveTo="tw-transform tw-max-h-0"
  >
    <div aria-expanded={collapsed}>{children}</div>
  </Transition>
);

export default Collapse;

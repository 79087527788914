import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { scrollToTop } from 'shared/utils/scroll';
import { contractSelector } from 'shared/selectors';
import {
  SELLER_ONBOARDING,
  SELLER_ONBOARDING_SWAN_ONBOARDING_RETURN,
} from 'app/routes';
import { isEcho } from 'models/Seller';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import WithContract from './WithContract';
import Submitted from './Submitted';
import SwanOnboardingReturn from './SwanOnboardingReturn';

import { Seller } from 'types';

const OnboardingStep = () => {
  useEffect(scrollToTop, []);

  const seller = useCurrentSeller() as Seller;
  const contract = useSelector(contractSelector);

  return (
    <>
      {isEcho(seller) && (
        <Route
          path={SELLER_ONBOARDING_SWAN_ONBOARDING_RETURN}
          component={SwanOnboardingReturn}
        />
      )}
      {contract ? (
        <WithContract
          basePath={SELLER_ONBOARDING}
          contract={contract}
          seller={seller}
        />
      ) : (
        <Submitted basePath={SELLER_ONBOARDING} seller={seller} />
      )}
    </>
  );
};

export default OnboardingStep;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { BankStatementAttachment } from 'types/Attachment';
import { load as loadReview } from 'shared/modules/review';
import { updateBankStatementAttachments } from 'signup/actions/SellerSignup';
import { ReduxState, Review, Seller } from 'types';

export interface FormData {
  bankStatementAttachments: BankStatementAttachment[];
}

const isValidAttachment = (attachment: BankStatementAttachment) =>
  attachment?.diagnostics?.type !== 'warning';

interface Params {
  review: Review;
  seller: Seller;
}

const useBankTransactionsImportModal = ({ review, seller }: Params) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [updatingAttachments, setUpdatingAttachments] = useState(false);

  const updateAttachments = async () => {
    setUpdatingAttachments(true);
    await dispatch(
      updateBankStatementAttachments(
        bankStatementAttachments.map((attachment) => attachment.id),
        'onboarding'
      )
    );
    setUpdatingAttachments(false);
    dispatch(loadReview(seller.id));
  };

  const attachmentsMap = useSelector(
    (state: ReduxState) => state.entities.attachments
  );
  const defaultAttachments = attachmentsMap
    ? seller.bankStatementAttachments.map(
        (attachmentId) => attachmentsMap[attachmentId]
      )
    : [];

  const {
    control,
    formState: { isSubmitting, touchedFields },
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      bankStatementAttachments: defaultAttachments,
    },
  });

  const removeInvalidAttachments = () => {
    setValue(
      'bankStatementAttachments',
      bankStatementAttachments.filter((attachment) =>
        isValidAttachment(attachment as BankStatementAttachment)
      ),
      { shouldTouch: true, shouldDirty: true }
    );
  };

  const bankStatementAttachments = useWatch({
    control,
    name: 'bankStatementAttachments',
  });

  useEffect(() => {
    if (touchedFields.bankStatementAttachments) {
      updateAttachments();
    }
  }, [bankStatementAttachments]);

  const attachmentsWithWarning = bankStatementAttachments.filter(
    (attachment) => !isValidAttachment(attachment as BankStatementAttachment)
  );

  const warnings =
    attachmentsWithWarning.length !== 0 ||
    review.missingPeriods.length > 0 ||
    false;

  return {
    attachmentsWithWarning,
    bankStatementAttachments,
    control,
    isSubmitting,
    removeInvalidAttachments,
    setUploading,
    uploading: uploading || updatingAttachments,
    warnings,
  };
};

export default useBankTransactionsImportModal;

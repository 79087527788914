import React from 'react';
import { Modal, Button } from 'shared/bootstrap';
import { nt } from '../utils';
import useDialog from './useDialog';
import LoadingError from 'shared/components/LoadingError';
import LoadingAnimation from 'shared/components/LoadingAnimation';

const t = nt('signup.onboarding.swan_onboarding.modal');

const Dialog = () => {
  const { redirectToOnboardingUrl, uboDeclarationFormUrl, urlStatus } =
    useDialog();

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {urlStatus === 'error' && <LoadingError />}
        {urlStatus === 'loading' && <LoadingAnimation />}
        <p>{t('description1')}</p>
        <p>{t('description2')}</p>
        {t('ubo_declaration_form_link_html', { url: uboDeclarationFormUrl })}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-primary"
          onClick={redirectToOnboardingUrl}
          disabled={urlStatus === 'loading'}
        >
          {t('button')}
        </Button>
      </Modal.Footer>
    </>
  );
};

export default Dialog;

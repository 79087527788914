import React, { useState } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';

import Action, { ActionButton, ActionClosingModal } from '../Action';
import VerifyAccountDialog from './VerifyAccountDialog';
import { OnboardingActionStatus, ReduxState, Seller } from 'types';
import { isDigitalBank } from 'models/Iban';
import { nt } from '../utils';
import client from 'shared/utils/client';
import { useDispatch, useSelector } from 'react-redux';
import updateEntities from 'shared/modules/updateEntities';
import * as I18n from 'shared/utils/I18n';

const t = nt('signup.onboarding.verify_account');

interface Props {
  basePath: string;
  seller: Seller;
  status: OnboardingActionStatus;
}

const ibanSelector = (state: ReduxState): string | undefined => {
  return state.onboarding.iban;
};

export default ({ basePath, seller, status }: Props) => {
  const [returnPath, setReturnPath] = useState<string | null>(null);
  const [serverError, setServerError] = useState<string | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const onboardingIban = useSelector(ibanSelector);

  if (isDigitalBank(seller.verifiedIban)) {
    return null;
  }

  if (returnPath) {
    return <Redirect to={returnPath} />;
  }

  const verifyAccountPath = `${basePath}/bankrekening`;

  const Button = () => (
    <ActionButton to={verifyAccountPath}>{t('button')}</ActionButton>
  );

  const onSubmit = async (digitalBank: boolean) => {
    if (digitalBank) {
      setReturnPath(`${basePath}/machtiging`);
    } else {
      const response = await client(
        'POST',
        `/api/sellers/${seller.id}/confirm_cent_transfer_for_iban_verification`,
        {},
        { raiseError: false }
      );

      if (response.error) {
        setServerError(I18n.nt('common', 'server_error'));
        return;
      }

      await dispatch(updateEntities(response.payload));
    }
  };

  const closeModal = () => history.replace(basePath);

  const renderModal = () => (
    <ActionClosingModal basePath={basePath}>
      <VerifyAccountDialog
        initialValues={{ iban: seller.iban || onboardingIban || '' }}
        serverError={serverError}
        onSubmit={onSubmit}
        onHide={closeModal}
      />
    </ActionClosingModal>
  );

  const showButton = status === 'Todo' || status === 'Pending';

  return (
    <>
      <Action
        button={showButton ? Button : null}
        description={t('description')}
        path={verifyAccountPath}
        status={status}
        title={t('title')}
      />
      <Route path={verifyAccountPath} render={renderModal} />
    </>
  );
};

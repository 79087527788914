import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller, ReduxState } from 'types';
import { getProduct } from 'models/Seller';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import { nt } from 'shared/utils/I18n';

const ns = 'app.products';

export interface PayOffInfo {
  florynIban: string | undefined;
  remainingRepaymentAmount?: string;
}

const payOffSelector = (state: ReduxState): PayOffInfo => {
  return {
    florynIban: state.charlie?.dashboard?.payload?.florynIban,
    remainingRepaymentAmount:
      state.charlie?.dashboard?.payload?.mortgageRemainingAnnuityAmount,
  };
};

const useDeltaExtraPayOffModal = (parentPath) => {
  const history = useHistory();
  const { florynIban, remainingRepaymentAmount } =
    useSelectorWithShallowCompare(payOffSelector);
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const onHide = () => {
    history.replace(parentPath);
  };

  const productName = nt(ns, getProduct(seller));
  let transferReference = seller.id.toString();
  transferReference += useIsComplexAccount() ? ` ${productName}` : '';

  return {
    florynIban,
    transferReference,
    remainingRepaymentAmount,
    onHide,
    seller,
  };
};

export default useDeltaExtraPayOffModal;

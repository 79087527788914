import { useState } from 'react';
import client from 'shared/utils/client';

type Status = '' | 'error' | 'loading' | 'loaded';

const useDialog = () => {
  const [urlStatus, setStatus] = useState<Status>('');

  const uboDeclarationFormUrl = '/api/onboarding/swan/ubo_declaration_form';

  const redirectToOnboardingUrl = async () => {
    setStatus('loading');

    const response = await client(
      'GET',
      `/api/onboarding/swan`,
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
      return;
    }

    const url = response.payload?.onboardingUrl;
    if (url) {
      window.open(url, '_self');
    }
  };

  return { redirectToOnboardingUrl, uboDeclarationFormUrl, urlStatus };
};

export default useDialog;

import React from 'react';
import { toPercentage } from 'shared/utils/Helpers';
import { nt } from 'shared/utils/I18n';

interface Props {
  transactionFeeRate: string;
}

const ns = 'charlie.frontpage.account_summary';

const TransactionCosts = ({ transactionFeeRate }: Props) => {
  return (
    <div className="tw-flex tw-gap-1 tw-text-right tw-font-semibold">
      <>
        {nt(ns, 'transaction_costs', {
          transaction_costs: toPercentage(transactionFeeRate),
        })}
      </>
    </div>
  );
};

export default TransactionCosts;

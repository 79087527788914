import React from 'react';
import { Col, Row } from 'shared/bootstrap';
import InformationField from 'shared/components/InformationField';
import { Company, Seller } from 'types';

interface Props {
  company: Company;
  seller: Seller;
}

const CompanyInformation = ({ company, seller }: Props) => {
  const {
    clientBankAccount,
    companyName,
    iban,
    phone,
    smallLogoUrl,
    invoiceIngressEmail,
  } = seller;
  const { city, houseNumber, houseNumberSuffix, kvk, street, postalCode } =
    company;

  return (
    <div className="company-information">
      <h4>{companyName}</h4>
      <Row>
        <Col lg={4}>
          <div className="address">
            <div>
              {street}{' '}
              {houseNumberSuffix
                ? `${houseNumber}-${houseNumberSuffix}`
                : `${houseNumber}`}
            </div>
            <div>
              {postalCode} {city}
            </div>
            <div>{phone}</div>
            <div>KvK: {kvk}</div>
          </div>
        </Col>
        <Col lg={4}>
          <InformationField field="iban" value={iban} model="seller" />
          {clientBankAccount && (
            <InformationField
              field="client_bank_account"
              value={clientBankAccount}
              model="seller"
            />
          )}
        </Col>
        <Col lg={4}>
          {smallLogoUrl && <img className="logo-small" src={smallLogoUrl} />}
          {invoiceIngressEmail && (
            <InformationField
              field="invoice_ingress_email"
              value={invoiceIngressEmail}
              model="seller"
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CompanyInformation;

import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as I18n from 'shared/utils/I18n';
import Button from 'shared/components/tailwind/Button';
import useAutomaticWithdrawal from './useAutomaticWithdrawal';
import useCanWithdraw from './useCanWithdraw';

const ns = 'charlie.frontpage.actions';

/* Display a withdraw button.
 * If automatic withdrawals are enabled: display the 'automatic withdrawal' button
 * Otherwise, display the default withdrawal button.
 */
const WithdrawButton = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const canWithdraw = useCanWithdraw();
  const { automaticWithdrawalActivated } = useAutomaticWithdrawal();

  const buttonTitle = automaticWithdrawalActivated
    ? I18n.nt(ns, 'automatic_withdraw_active')
    : I18n.nt(ns, 'withdraw_money');

  const url = automaticWithdrawalActivated
    ? `${match.path}/automatisch-opnemen`
    : `${match.path}/geld-opnemen`;

  return (
    <Button
      className="btn btn-primary tw-flex-grow xs:tw-grow-0"
      disabled={!canWithdraw}
      onClick={() => history.replace(url)}
    >
      {buttonTitle}
    </Button>
  );
};

export default WithdrawButton;

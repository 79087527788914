import React from 'react';
import { Tabs } from 'shared/bootstrap';
import { nt } from 'shared/utils/I18n';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import InvoiceForm from './children/InvoiceForm';
import InvoiceTitle from './children/InvoiceTitle';
import InvoiceStatus from './children/InvoiceStatus';
import InvoiceSummary from './children/InvoiceSummary';
import InvoiceEmailHistory from 'shared/components/InvoiceEmailHistory';
import InvoiceActions from './children/InvoiceActions';
import NotFound from '../../NotFound';
import useInvoice from './useInvoice';

const ns = 'app.invoiceTabs';

const InvoiceComponent = () => {
  const {
    company,
    initialTab,
    invoice,
    invoiceContactsMap,
    invoiceIdParam,
    invoiceLoading,
    onInvoiceAction,
    onRequestFinancing,
    performingAction,
    readonly,
    seller,
    sellerLoading,
    showSubmitSummary,
    tabKey,
    tabSelect,
  } = useInvoice();

  const invoiceState = invoice?.state || 'draft';

  const renderLeft = () => {
    if (
      showSubmitSummary ||
      readonly ||
      ['submitted', 'withdrawn'].includes(invoiceState) ||
      (invoiceState !== 'draft' && invoice.invoiceContacts.length === 0)
    ) {
      return (
        seller &&
        invoice && (
          <InvoiceSummary
            invoice={invoice}
            invoiceContactsMap={invoiceContactsMap}
          />
        )
      );
    }

    if (invoiceState === 'draft') {
      return (
        <div>
          <InvoiceForm seller={seller} />
        </div>
      );
    }

    const tabs = [
      {
        key: 'overzicht',
        title: nt(ns, 'summary'),
        content: seller && (
          <InvoiceSummary
            invoice={invoice}
            invoiceContactsMap={invoiceContactsMap}
          />
        ),
      },
      {
        key: 'emails',
        title: nt(ns, 'emails'),
        content: <InvoiceEmailHistory emails={invoice.emails} />,
      },
    ];

    return <Tabs initialTab={initialTab} onSelectTab={tabSelect} tabs={tabs} />;
  };

  if (
    invoiceLoading ||
    sellerLoading ||
    (!invoice && invoiceIdParam !== 'new') ||
    (invoice && invoice.entityKind.startsWith('Minimal'))
  ) {
    return <LoadingAnimation />;
  }

  if (tabKey && !['emails', 'overzicht'].includes(tabKey)) {
    return <NotFound />;
  }

  const emptyInvoice = {
    invoiceContacts: [],
    state: 'draft',
    verificationState: 'not_sent',
  };
  const invoiceForInvoiceStatus = invoice || emptyInvoice;
  const invoiceContacts = invoiceForInvoiceStatus.invoiceContacts.map(
    (id) => invoiceContactsMap[id]
  );
  const showActions = invoice && tabKey !== 'emails' && !readonly;

  return (
    <div className="Invoice">
      <InvoiceTitle invoice={invoice} readonly={readonly} />
      <div className="left">{renderLeft()}</div>
      {seller && (
        <InvoiceStatus
          invoice={invoiceForInvoiceStatus}
          invoiceContacts={invoiceContacts}
          seller={seller}
          verificationState={invoiceForInvoiceStatus.verificationState}
        />
      )}
      {showActions && (
        <InvoiceActions
          company={company}
          invoice={invoice}
          onInvoiceAction={onInvoiceAction}
          onRequestFinancing={onRequestFinancing}
          performingAction={performingAction}
          seller={seller}
        />
      )}
    </div>
  );
};

export default InvoiceComponent;

import React from 'react';
import Badge from './Badge';
import { IbanTask } from './modules/IbanTask';
import { formatIban, dateFormat } from 'shared/utils/Helpers';
import { translate } from 'shared/utils/I18n';
import Panel from './Panel';
import Warnings from './Warnings';

const t = translate('review_wizard.iban_panel');

interface Props {
  ibanTask: IbanTask;
}

const DeliveryType = ({ ibanTask }: Props) => {
  switch (ibanTask.delivery) {
    case 'pdf':
      return <div>{t('pdf')}</div>;
    case 'psd2':
      return <div>{t('psd2')}</div>;
    case 'both':
      return <div>{t('both')}</div>;
    default:
      return <div />;
  }
};

const IbanPanel = ({ ibanTask }: Props) => (
  <Panel
    data-testid="iban-panel"
    className="rw-sidebar rw-sidebar--left rw-gap-12"
  >
    <div>
      {(ibanTask.status === 'completed' ||
        ibanTask.status === 'expiresSoon') && <Badge status="ok" />}
      {ibanTask.status === 'pending' && <Badge status="pending" />}
      {ibanTask.status === 'warnings' && <Badge status="warning" />}
    </div>
    <div className="rw-sidebar rw-sidebar--right rw-items-start">
      <div>
        <div className="rw-panel__title">{formatIban(ibanTask.iban)}</div>
        <div className="rw-cluster">
          <div>
            <DeliveryType ibanTask={ibanTask} />
          </div>
          <div className="rw-truncate">
            {ibanTask.consent && ibanTask.consent.expiresOn
              ? t('expires_on', {
                  date: dateFormat(new Date(ibanTask.consent.expiresOn)),
                })
              : ibanTask.message}
          </div>
        </div>
        <div>
          {ibanTask.status === 'expiresSoon' &&
            ibanTask.missingPeriods.length > 0 && (
              <div>
                {t('expires_soon', {
                  date: dateFormat(
                    new Date(ibanTask.missingPeriods[0].endDate)
                  ),
                })}
              </div>
            )}
          {ibanTask.status === 'warnings' && (
            <Warnings missingPeriods={ibanTask.missingPeriods} />
          )}
        </div>
      </div>
      <div className="md:text-right hidden md:block">
        {ibanTask.bank !== 'unknown' && (
          <img
            className="iban_panel__bank_logo"
            src={`/images/banks/${ibanTask.bank}.svg`}
          />
        )}
      </div>
    </div>
  </Panel>
);

export default IbanPanel;

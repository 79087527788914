import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import client, { Result } from 'shared/utils/client';
import { ReduxState } from 'types/ReduxState';
import { BaseReview } from 'types/Review';
import { SUBMIT as SUBMIT_REVIEW } from 'shared/modules/review';
import { DashboardChildMutation, MutationInvoice } from 'types';

export interface DashboardMutation {
  amount: string;
  bankEntry: any | null;
  createdAt: string | null;
  childMutations: DashboardChildMutation[] | null;
  currentCommitment: string | null;
  currentLiable: string | null;
  currentTransfer: string | null;
  date: string | null;
  description: string;
  id: number;
  invoice: MutationInvoice | null;
  mutationType: string;
  title: string;
  vatAmount: string | null;
  metaData: any | null;
}

interface CharlieDashboardPayload {
  automaticWithdrawalPermitted: boolean;
  automaticWithdrawalActivated: boolean;
  availableBalance: string;
  baseInterestRate: string;
  canWithdraw: boolean;
  charlieCollectionMethod: string;
  closingCommissionAmount: string;
  creditLimit: string;
  currentBalance: string;
  currentCommitment?: {
    amount: string;
    message: string;
  };
  currentPage: number;
  debtCollectionRate: string;
  debtCollectionForNewWithdrawal?: {
    amount: string;
    date: string;
  };
  eligibleForReactivation: boolean;
  euriborRate: string;
  exactOnlineConnected: boolean;
  fixedAutomaticWithdrawalLimit: string;
  florynIban: string;
  idealEnabled: boolean;
  interestRate: string;
  lastPage: boolean;
  latestInterestChange?: {
    date: string;
    rateChange: string;
  };
  liable: string;
  maxRemainingExtraRepaymentAmount: string;
  minimumWithdrawalAmount: string;
  mortgageCurrentTerm: number;
  mortgageDuration: number;
  mortgageInterestOnlyComponent: string;
  mortgageEndDate: string;
  mortgageRemainingAnnuityAmount: string;
  mortgageRepaidAnnuityAmount: string;
  mortgageRemainingDebt: string;
  mutations: DashboardMutation[];
  nextDebtCollection?: {
    amount: string;
    date: string;
  };

  nextRestriction?: {
    date: string;
    newLimit: string;
    stepAmount: string;
  };
  pendingCurrentBalance: string;
  pendingCurrentLimit: string;
  pendingSettlementDate: string;
  rejectedWithdrawalAmount: string;
  requestedAmount: string;
  reviewInfo?: BaseReview;
  serviceFeeRate: string;
  settlement: string;
  latestImport: string;
  settlementCalculated: boolean;
  settlementToBeCalculated: boolean;
  today: string;
  transactionFeeRate?: string;
  withdrawalFeeRate: string;
  hasCustomerActionableDeliveries: boolean;
}

export interface CharlieDashboardState {
  dashboardStatus: '' | 'LOADED' | 'LOADING';
  mutationsStatus: '' | 'LOADED' | 'LOADING';
  payload: CharlieDashboardPayload | null;
  searching: boolean;
  searchQuery: string;
}

interface QueryAttributes {
  query: string;
  sellerId: number;
}

interface WithdrawParams {
  amount: number;
  sellerId: number;
}

export const loadDashboard = createAsyncThunk<CharlieDashboardPayload, number>(
  'charlie.loadDashboard',
  async (sellerId: number) => {
    const response = await client('GET', '/api/app/dashboard', { sellerId });
    return response.payload;
  }
);

export const loadMoreMutations = createAsyncThunk<
  CharlieDashboardPayload,
  void,
  { state: ReduxState }
>('charlie.loadMoreMutations', async (_, { getState }) => {
  const currentPage = getState().charlie.dashboard?.payload?.currentPage || 0;
  const currentSellerId = getState().session.currentSellerId;

  const response = await client('GET', '/api/app/dashboard', {
    page: `${currentPage + 1}`,
    seller_id: currentSellerId,
  });
  return response.payload;
});

export const search = createAsyncThunk<
  CharlieDashboardPayload,
  QueryAttributes
>('charlie.search', async ({ query, sellerId }) => {
  const response = await client('GET', '/api/app/dashboard', {
    query,
    sellerId,
  });
  return response.payload;
});

export const withdraw = createAsyncThunk<
  CharlieDashboardPayload,
  WithdrawParams,
  {
    rejectValue: Result;
  }
>('charlie.withdraw', async ({ amount, sellerId }, { rejectWithValue }) => {
  const response = await client(
    'POST',
    '/api/app/withdraw',
    {
      amount,
      sellerId,
    },
    { raiseError: false }
  );

  if (response.error) {
    return rejectWithValue(response);
  } else {
    return response.payload;
  }
});

export const setAutomaticWithdrawal = createAsyncThunk<
  CharlieDashboardPayload,
  { sellerId: number; amount: number },
  {
    rejectValue: Result;
  }
>(
  'charlie.setAutomaticWithdrawal',
  async ({ sellerId, amount }, { rejectWithValue }) => {
    const response = await client(
      'POST',
      '/api/app/set_automatic_withdrawal',
      {
        sellerId,
        amount,
      },
      { raiseError: false }
    );

    if (response.error) {
      return rejectWithValue(response);
    } else {
      return response.payload;
    }
  }
);

const initialState: CharlieDashboardState = {
  dashboardStatus: '',
  mutationsStatus: '',
  payload: null,
  searching: false,
  searchQuery: '',
};

export const updateReviewInfo = createAction<BaseReview>(
  'charlie.updateReviewInfo'
);

const dashboardSlice = createSlice({
  name: 'charlie.dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadDashboard.pending, (state) => {
      state.dashboardStatus = 'LOADING';
      state.mutationsStatus = 'LOADING';
    });
    builder.addCase(loadDashboard.fulfilled, (state, { payload }) => {
      state.dashboardStatus = 'LOADED';
      state.mutationsStatus = 'LOADED';
      state.payload = payload;
    });

    builder.addCase(updateReviewInfo, (state, payload) => {
      if (state.payload) {
        state.payload.reviewInfo = payload.payload;
      }
    });

    builder.addCase(loadMoreMutations.pending, (state) => {
      state.mutationsStatus = 'LOADING';
    });
    builder.addCase(loadMoreMutations.fulfilled, (state, { payload }) => {
      state.mutationsStatus = 'LOADED';

      if (!state.payload) {
        state.payload = payload;
      } else {
        const {
          currentPage,
          lastPage,
          mutations: newMutations,
          today,
        } = payload;
        const originalMutations = state.payload?.mutations || [];
        const mutations = [...originalMutations, ...newMutations];

        state.payload.currentPage = currentPage;
        state.payload.lastPage = lastPage;
        state.payload.mutations = mutations;
        state.payload.today = today;
      }
    });

    builder.addCase(search.pending, (state, { meta }) => {
      state.searching = true;
      state.searchQuery = meta.arg.query;
    });
    builder.addCase(search.fulfilled, (state, { payload }) => {
      state.searching = false;
      state.payload = payload;
    });

    builder.addCase(SUBMIT_REVIEW.SUCCESS, (state, { payload }) => {
      if (state.payload) {
        state.payload.reviewInfo = payload.review;
      }
    });

    builder.addCase(setAutomaticWithdrawal.fulfilled, (state, { payload }) => {
      state.payload = payload;
    });

    builder.addCase(withdraw.fulfilled, (state, { payload }) => {
      state.payload = payload;
    });

    builder.addCase(withdraw.rejected, () => {
      // Do not update the state.
    });
  },
});

export default dashboardSlice.reducer;

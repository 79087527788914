import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Location } from 'history';
import { useSelector } from 'react-redux';
import { map } from 'lodash';

import * as routes from 'app/routes';
import InactiveSeller from 'signup/components/InactiveSeller';
import Reactivation from 'signup/components/reactivation';
import Mandate from 'signup/containers/Mandate';
import MandateReturn from 'signup/containers/MandateReturn';
import NotFound from 'app/components/NotFound';
import ProfilePage from 'app/components/seller/ProfilePage';
import SellerRoot from 'app/components/seller/SellerRoot';
import Signup from 'signup/containers/SellerSignup';
import Onboarding from 'Onboarding';
import FinancialDashboard from 'FinancialDashboard';
import getProductConfig from 'productConfigs';
import { isActive } from 'models/Seller';
import { currentSellerSelector } from 'shared/selectors';
import {
  RouteForSeller,
  RouteForSellerStatus,
  ConditionalRedirectRoute,
} from 'shared/utils/routing';
import useCustomerAccount from 'shared/hooks/useCustomerAccount';
import useSession from 'shared/hooks/useSession';
import { getQueryParam } from 'shared/utils/Helpers';
import { CustomerAccount, MinimalSeller } from 'types';
import { Status } from 'types/Seller';
import { frontpageRoute } from 'shared/routes';
import {
  useCanAccessSignupRoute,
  useCanAccessOnboardingRoute,
} from 'shared/hooks/useCanAccessRoutes';

const getFinancialDashboardRedirectUrl = (
  location: Location,
  seller: MinimalSeller,
  customerAccount: CustomerAccount
) => {
  const statusFromAis = getQueryParam(location.search, 'status');
  const isRedirectedFromAis = Boolean(statusFromAis);

  if (!isRedirectedFromAis) {
    return undefined;
  }

  if (seller.status === 'draft') {
    return `${routes.SELLER_SIGNUP_BANK_STATEMENTS_STEP}?status=${statusFromAis}`;
  }

  const redirectUrl = frontpageRoute(customerAccount, seller, null);
  return `${redirectUrl}?status=${statusFromAis}`;
};

const Routes = () => {
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const customerAccount = useCustomerAccount();
  const location = useLocation();
  const productConfig = getProductConfig(seller.productVersion);
  const financialDashboardRedirectUrl = getFinancialDashboardRedirectUrl(
    location,
    seller,
    customerAccount
  );
  const canAccessSignupRoute = useCanAccessSignupRoute();
  const canAccessOnboardingRoute = useCanAccessOnboardingRoute();

  const session = useSession();

  return (
    <Switch>
      <RouteForSellerStatus
        exact
        path={[
          routes.PROFILE_PAGE_TAB,
          routes.PROFILE_PAGE,
          routes.PROFILE_PAGE_CONSENTS,
          routes.PROFILE_PAGE_CONSENTS_CONNECT,
        ]}
        component={ProfilePage}
        status={[Status.APPROVED, Status.CANCELLED]}
      />
      {seller.canViewFinancialDashboard && (
        <RouteForSellerStatus
          path={routes.DASHBOARD}
          component={FinancialDashboard.ForCharlie}
          status={[Status.APPROVED, Status.CANCELLED]}
        />
      )}
      <RouteForSeller
        path={routes.SELLER_ONBOARDING}
        component={Onboarding}
        condition={() => canAccessOnboardingRoute}
      />
      <ConditionalRedirectRoute
        path={routes.FINANCIAL_DASHBOARD}
        component={FinancialDashboard.ForSignup}
        redirectTo={financialDashboardRedirectUrl}
        redirectWhen={Boolean(financialDashboardRedirectUrl)}
      />
      {session.authenticationMethod !== 'token' && (
        <RouteForSeller
          path={routes.SELLER_SIGNUP_STEP}
          component={Signup}
          condition={() => canAccessSignupRoute}
        />
      )}
      <Route exact path={routes.MANDATE} component={Mandate} />
      <Route exact path={routes.MANDATE_RETURN} component={MandateReturn} />
      {map(productConfig.routes, (routeProps, index) => (
        <RouteForSellerStatus
          {...routeProps}
          key={index}
          status={['approved', 'cancelled']}
        />
      ))}
      <Route component={NotFound} />
    </Switch>
  );
};

const Sellers = () => {
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const productConfig = getProductConfig(seller.productVersion);

  return (
    <SellerRoot navBarLinks={productConfig.navBarLinks} seller={seller}>
      <Switch>
        <RouteForSellerStatus
          path={routes.INACTIVE}
          component={InactiveSeller}
          status={Status.INACTIVE}
        />
        <RouteForSellerStatus
          path={routes.REACTIVATION}
          component={Reactivation}
          status={[Status.LOST, Status.REJECTED]}
        />
        <RouteForSeller
          path={[routes.CUSTOMER_APP_ROOT, routes.OVERVIEW_PAGE_TAB]}
          component={Routes}
          condition={isActive}
        />
      </Switch>
    </SellerRoot>
  );
};

export default Sellers;

import React from 'react';
import Badge from './Badge';
import Panel from './Panel';
import UploadedFile from './UploadedFile';
import { Attachment } from './types';
import { translate } from 'shared/utils/I18n';

const t = translate('review_wizard.unrecognised_files_panel');

interface Props {
  onDeleteAttachment: (id: number) => void;
  unrecognisedFiles: Attachment[];
}

const UnrecognisedFilesPanel = ({
  onDeleteAttachment,
  unrecognisedFiles,
}: Props) => (
  <Panel className="rw-sidebar rw-sidebar--right rw-unrecognised-files">
    <div className="rw-sidebar rw-sidebar--left rw-gap-12">
      <div>
        <Badge status="warning" glyph="file" />
      </div>
      <div className="rw-space-y-16">
        <div className="rw-panel__title">{t('title')}</div>
        <div>{t('hint_html')}</div>
        <div>
          <ul>
            {unrecognisedFiles.map((attachment) => (
              <UploadedFile
                key={attachment.id}
                onDeleteAttachment={onDeleteAttachment}
                uploadedFile={attachment}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  </Panel>
);

export default UnrecognisedFilesPanel;

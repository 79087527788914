import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { map } from 'lodash';

import * as I18n from 'shared/utils/I18n';
import * as routes from 'app/routes';
import { frontpageRoute } from 'shared/routes';
import { useCurrentSeller } from 'shared/hooks';
import useCustomerAccount from 'shared/hooks/useCustomerAccount';

export interface LinkDefinition {
  icon?: string;
  path: string;
  title: string;
}

interface Props {
  links: LinkDefinition[];
}

const ns = 'app.nav';

const Logo = ({ customerAccount, seller }) => {
  const today = new Date();
  const christmas = today.getMonth() === 11 && today.getDate() >= 14;
  const logo = christmas ? 'logo-christmas' : 'logo';

  return (
    <Link to={frontpageRoute(customerAccount, seller, null)}>
      <img alt="Floryn" src={`/images/${logo}.svg`} className="tw-w-[130px]" />
    </Link>
  );
};

const Hamburger = ({ children }) => (
  <>
    <div
      id="hamburger"
      className="tw-flex tw-h-6 tw-w-6 tw-flex-col tw-items-center tw-justify-around hover:tw-cursor-pointer"
    >
      <div className="tw-h-0.5 tw-w-6 tw-rounded-lg tw-bg-white" />
      <div className="tw-h-0.5 tw-w-6 tw-rounded-lg tw-bg-white" />
      <div className="tw-h-0.5 tw-w-6 tw-rounded-lg tw-bg-white" />
    </div>
    {children}
  </>
);

const Cross = ({ onClick }) => (
  <div
    className="tw-flex tw-h-6 tw-flex-col tw-justify-around hover:tw-cursor-pointer"
    onClick={onClick}
  >
    <div className="tw-h-0.5 tw-w-6 tw-rotate-45 tw-rounded-lg tw-bg-white" />
    <div className="-tw-mt-6 tw-h-0.5 tw-w-6 -tw-rotate-45 tw-rounded-lg tw-bg-white" />
  </div>
);

const NavigationBarApproved = ({ links }: Props) => {
  const customerAccount = useCustomerAccount();
  const seller = useCurrentSeller();

  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  const toggleHamburgerMenu = () => setHamburgerMenuOpen(!hamburgerMenuOpen);

  return (
    <>
      <nav className="DesktopOnly">
        <div className="tw-bg-floryn-bleen">
          <div className="container">
            <div className="tw-flex tw-h-[72px] tw-justify-between">
              <div className="tw-flex tw-items-center tw-gap-10">
                <Logo customerAccount={customerAccount} seller={seller} />
                {map(links, (link, index) => (
                  <Link
                    key={index}
                    to={link.path}
                    className="tw-navbar-link tw-navbar-hover"
                  >
                    <span
                      className={
                        link.icon && classNames('icon', `icon-${link.icon}`)
                      }
                    />
                    {link.title}
                  </Link>
                ))}
                {seller.canViewFinancialDashboard && (
                  <Link
                    to={routes.DASHBOARD}
                    className="tw-navbar-link tw-navbar-hover"
                  >
                    <span className="icon icon-dashboard" />
                    {I18n.nt(ns, 'dashboard')}
                  </Link>
                )}
              </div>
              <div className="tw-flex tw-items-center tw-gap-10">
                <Link
                  to={routes.PROFILE_PAGE}
                  className="tw-navbar-link tw-navbar-hover"
                >
                  <span className="icon icon-user1" />
                  {I18n.nt(ns, 'account')}
                </Link>
                <Link
                  to={routes.LOGOUT}
                  className="tw-navbar-link tw-navbar-hover"
                >
                  <span className="icon icon-logout" />
                  {I18n.nt(ns, 'logout')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <nav className="MobileOnly">
        <div className="tw-bg-floryn-bleen">
          <div className="tw-mx-4 tw-flex tw-h-[60px] tw-items-center tw-justify-between sm:tw-mx-8">
            <Logo customerAccount={customerAccount} seller={seller} />
            <div onClick={toggleHamburgerMenu}>
              <Hamburger>
                <div
                  className={classNames(
                    'tw-fixed tw-left-0 tw-top-0 tw-z-50 tw-h-screen tw-w-screen tw-flex-col tw-bg-floryn-bleen',
                    hamburgerMenuOpen ? 'tw-flex' : 'tw-hidden'
                  )}
                >
                  <div className="tw-mx-4 tw-flex tw-h-[60px] tw-items-center tw-justify-between sm:tw-mx-8">
                    <Logo customerAccount={customerAccount} seller={seller} />
                    <Cross onClick={toggleHamburgerMenu} />
                  </div>
                  <div className="tw-flex tw-flex-col tw-gap-2 tw-p-4 sm:tw-mx-4">
                    {map(links, (link, index) => (
                      <Link
                        key={index}
                        to={link.path}
                        className="tw-navbar-link tw-py-2"
                      >
                        <span
                          className={
                            link.icon && classNames('icon', `icon-${link.icon}`)
                          }
                        />
                        {link.title}
                      </Link>
                    ))}
                    {seller.canViewFinancialDashboard && (
                      <Link
                        to={routes.DASHBOARD}
                        className="tw-navbar-link tw-py-2"
                      >
                        <span className="icon icon-dashboard" />
                        {I18n.nt(ns, 'dashboard')}
                      </Link>
                    )}
                    <Link
                      to={routes.PROFILE_PAGE}
                      className="tw-navbar-link tw-py-2"
                    >
                      <span className="icon icon-user1" />
                      {I18n.nt(ns, 'account')}
                    </Link>
                    <Link to={routes.LOGOUT} className="tw-navbar-link tw-py-2">
                      <span className="icon icon-logout" />
                      {I18n.nt(ns, 'logout')}
                    </Link>
                  </div>
                </div>
              </Hamburger>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavigationBarApproved;

import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import client from 'shared/utils/client';
import useLoadFullSeller from 'shared/hooks/useLoadFullSeller';
import { SELLER_ONBOARDING } from 'app/routes';

type Status = '' | 'error' | 'loading' | 'loaded';

const useOnboardingReturn = () => {
  const [status, setStatus] = useState<Status>('');
  const [onboardingStatus, setOnboardingStatus] = useState('');
  const [onboardingUrl, setOnboardingUrl] = useState('');
  const history = useHistory();
  const loadFullSeller = useLoadFullSeller();

  const refreshOnboardingStatus = async () => {
    setStatus('loading');

    const response = await client(
      'GET',
      '/api/onboarding/swan',
      {},
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
      return;
    }

    setOnboardingStatus(response.payload.onboardingState);
    setOnboardingUrl(response.payload.onboardingUrl);
    await loadFullSeller();
    setStatus('loaded');
  };

  const redirectToOnboardingUrl = () => {
    if (onboardingUrl) {
      window.open(onboardingUrl, '_self');
    }
  };

  const closeModal = () => history.push(SELLER_ONBOARDING);

  useEffect(() => {
    refreshOnboardingStatus();
  }, []);

  return { closeModal, onboardingStatus, redirectToOnboardingUrl, status };
};

export default useOnboardingReturn;

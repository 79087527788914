import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { createSelector } from 'reselect';

import { pick, values } from 'lodash';

import { mainSellerSelector, userSelector } from 'shared/selectors';
import { compilePath } from 'shared/utils/routing';
import useEnsureFullSellers from 'shared/hooks/useEnsureFullSellers';
import { PROFILE_PAGE_TAB } from 'app/routes';
import { resetState } from 'app/modules/profilePage';
import { Company, ReduxState, Seller, User } from 'types';
import useAppDispatch from 'shared/hooks/useAppDispatch';

interface RouteParams {
  tabKey: string;
}

const useProfilePage = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [initialTab, setInitialTab] = useState<string | undefined>();

  const { tabKey } = useParams<RouteParams>();

  const user = useSelector(userSelector) as User;

  const { sellers, status } = useEnsureFullSellers(user.customerAccountId);

  const mainSeller = useSelector(mainSellerSelector) as Seller;

  const companyIds = sellers.map((seller) => seller.company);

  const companyWithIdsSelector = createSelector(
    (state: ReduxState) => state.entities.companies,
    (companies: Company[]) =>
      companyIds ? values(pick(companies, companyIds)) : []
  );
  const companies = useSelector(companyWithIdsSelector);

  const displayExactOnlineTab = sellers.some(
    (seller) => seller.clientBankAccount
  );

  const selectTab = (tabKey: string) => {
    dispatch(resetState());

    const url = compilePath(PROFILE_PAGE_TAB, { tabKey });
    history.push(url);
  };

  useEffect(() => {
    if (tabKey) {
      setInitialTab(tabKey);
    }

    return () => dispatch(resetState());
  }, []);

  return {
    companies,
    displayExactOnlineTab,
    initialTab,
    mainSeller,
    selectTab,
    sellers,
    status,
    user,
  };
};

export default useProfilePage;

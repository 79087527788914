import React from 'react';
import LoginForm from './LoginForm';
import Otp from './Otp';
import DevelopmentLogin from './DevelopmentLogin';
import useLogin from './useLogin';

const Login = () => {
  const { login, otp, status, switchToNormalLogin } = useLogin();

  switch (status) {
    case '':
    case 'account_locked':
    case 'authenticated':
    case 'authenticating':
    case 'invalid_login':
    case 'server_error':
      return <LoginForm login={login} status={status} />;
    case 'development_login':
      return <DevelopmentLogin switchToNormalLogin={switchToNormalLogin} />;
    case 'otp_login':
      if (otp) {
        return (
          <Otp
            intermediaryToken={otp.intermediaryToken}
            lastThreeNumbers={otp.lastThreeNumbers}
          />
        );
      }
  }

  return null;
};

export default Login;

import { nt } from 'shared/utils/I18n';
import { toPercentage } from 'shared/utils/Helpers';
import React from 'react';
import { Seller } from 'types/Seller';

const ns = 'signup.onboarding.debt_collection_info.charlie';

interface Props {
  seller: Seller;
  debtCollectionRate: number;
}

const DebtCollectionInfoCharlie = ({ seller, debtCollectionRate }: Props) => {
  return (
    <div className="CostExplanation">
      <h4>{nt(ns, 'title')}</h4>
      <ul>
        <li>
          {nt(ns, 'explanation1_html', {
            percentage: toPercentage(debtCollectionRate),
          })}
        </li>
        {seller.charlieCollectionMethod && (
          <li>{nt(ns, `explanation2_${seller.charlieCollectionMethod}`)}</li>
        )}
        <li>{nt(ns, 'explanation3')}</li>
        <li>{nt(ns, 'explanation4')}</li>
      </ul>
    </div>
  );
};

export default DebtCollectionInfoCharlie;

import React from 'react';
import { Modal } from 'shared/bootstrap';
import { useHistory } from 'react-router-dom';

import { CUSTOMER_APP_ROOT } from 'app/routes';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import * as I18n from 'shared/utils/I18n';

function NoOp({ children }) {
  return children;
}

const ns = 'app.dashboardPage';

const EnsureNoComplexAccountModal = ({ disabledWrapper, children }) => {
  const history = useHistory();
  const close = () => history.replace(CUSTOMER_APP_ROOT);

  const Wrapper = disabledWrapper || NoOp;

  const complexAccount = useIsComplexAccount();

  if (!complexAccount) {
    return children;
  }

  return (
    <Wrapper>
      <Modal show={true} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{I18n.nt(ns, 'complex_account_description')}</p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default EnsureNoComplexAccountModal;

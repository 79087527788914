import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import ServerError from 'shared/components/ServerError';
import { nt } from '../utils';
import useSwanOnboardingReturn from './useSwanOnboardingReturn';

const t = nt('signup.onboarding.swan_onboarding.return');

const SwanOnboardingReturn = () => {
  const { closeModal, onboardingStatus, redirectToOnboardingUrl, status } =
    useSwanOnboardingReturn();

  return (
    <Modal show onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {status === 'loaded' && (
          <div>
            {onboardingStatus === 'completed'
              ? t('loaded_completed')
              : t('loaded_todo')}
          </div>
        )}
        {status === 'loading' && (
          <div>
            <div>{t('loading')}</div>
            <LoadingAnimation />
          </div>
        )}
        {status === 'error' && <ServerError />}
      </Modal.Body>
      <Modal.Footer>
        {status === 'loaded' && onboardingStatus !== 'completed' && (
          <>
            <Button className="btn-primary" onClick={redirectToOnboardingUrl}>
              {t('button')}
            </Button>
            <Button className="btn-default" onClick={closeModal}>
              {t('close')}
            </Button>
          </>
        )}
        {(status === 'error' ||
          (status === 'loaded' && onboardingStatus === 'completed')) && (
          <Button className="btn-primary" onClick={closeModal}>
            {t('close')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SwanOnboardingReturn;

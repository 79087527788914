import React from 'react';
import { Route } from 'react-router-dom';

import Action, { ActionButton, ActionClosingModal } from '../Action';
import Dialog from './Dialog';
import { OnboardingActionStatus } from 'types';
import { nt } from '../utils';

const t = nt('signup.onboarding.swan_onboarding.action');

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
}

const SwanOnboarding = ({ basePath, status }: Props) => {
  const swanOnboardingPath = `${basePath}/swan_onboarding`;

  const Button = () => (
    <ActionButton to={swanOnboardingPath}>{t('button')}</ActionButton>
  );

  const renderModal = () => (
    <ActionClosingModal basePath={basePath}>
      <Dialog />
    </ActionClosingModal>
  );

  const description =
    status === 'Todo' ? t('description_todo') : t('description_completed');

  return (
    <>
      <Action
        button={status === 'Todo' ? Button : null}
        description={description}
        path={status === 'Todo' ? swanOnboardingPath : undefined}
        status={status}
        title={t('title')}
      />
      <Route path={swanOnboardingPath} render={renderModal} />
    </>
  );
};

export default SwanOnboarding;

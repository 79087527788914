import React, { JSX, useState } from 'react';
import { Tab as HeadlessTab, Transition } from '@headlessui/react';
import classNames from 'classnames';

interface TabInfo {
  content: JSX.Element;
  key: string;
  title: string;
}

interface TabsProps {
  initialTab?: string;
  onSelectTab?: (tab: string) => void;
  tabs: TabInfo[];
}

const tabClassName = ({ selected }: { selected: boolean }) =>
  classNames(
    'tw-py-2 tw-px-4 tw-mb-[-1px] tw-mr-[2px] focus:tw-outline-2 focus:tw-outline-floryn-middle-blue',
    selected
      ? 'tw-border tw-border-gray-300 tw-border-b-white tw-rounded-sm'
      : 'tw-border tw-border-transparent tw-text-floryn-light-blue hover:tw-bg-gray-200 hover:tw-border-t hover:tw-border-t-gray-200 hover:tw-text-floryn-light-blue-hover'
  );

const getSelectedTabIndex = (tabKey: string, tabs: TabInfo[]) => {
  const selectedIndex = tabs.findIndex((tab) => tab.key === tabKey);

  return selectedIndex === -1 ? 0 : selectedIndex;
};

const Tabs = ({ initialTab, onSelectTab, tabs }: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(
    initialTab ? getSelectedTabIndex(initialTab, tabs) : 0
  );

  const handleChange = (index: number) => {
    setActiveTabIndex(index);

    if (onSelectTab) {
      onSelectTab(tabs[index].key);
    }
  };

  return (
    <HeadlessTab.Group selectedIndex={activeTabIndex} onChange={handleChange}>
      <HeadlessTab.List className="tw-border-b tw-border-gray-300">
        {tabs.map(({ key, title }) => (
          <HeadlessTab className={tabClassName} key={key}>
            {title}
          </HeadlessTab>
        ))}
      </HeadlessTab.List>
      <HeadlessTab.Panels className="tw-mt-8">
        {tabs.map(({ content, key }, i) => (
          <HeadlessTab.Panel key={key} static>
            <Transition
              show={activeTabIndex === i}
              enter="tw-transition-opacity tw-duration-150"
              enterFrom="tw-opacity-0"
              enterTo="tw-opacity-100"
              leave="tw-transition-opacity tw-duration-150"
              leaveFrom="tw-opacity-100"
              leaveTo="tw-opacity-0"
            >
              {content}
            </Transition>
          </HeadlessTab.Panel>
        ))}
      </HeadlessTab.Panels>
    </HeadlessTab.Group>
  );
};

export default Tabs;

import React from 'react';

import { Alert } from 'shared/bootstrap';
import ReviewIntroductionMessage from 'shared/components/ReviewMessages/ReviewIntroductionMessage';
import { useCurrentSeller } from 'shared/hooks';
import { dateFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { isAlfa, isCancelled } from 'models/Seller';
import useReviewInfo from 'shared/hooks/useReviewInfo';

import UploadBankstatementLink from './UploadBankstatementLink';
import useReviewStatusForProduct from 'charlie/components/ReactivationModal/useReviewStatusForProduct';

const ns = 'charlie.frontpage.warnings';

const ReviewWarning = () => {
  const currentSeller = useCurrentSeller();
  const reviewStatusForProduct = useReviewStatusForProduct();
  const reviewInfo = useReviewInfo();

  if (isCancelled(currentSeller) || !reviewInfo) {
    return null;
  }

  switch (reviewStatusForProduct) {
    case 'finished':
      return null;

    case 'open':
      return (
        <Alert type="warning" className="c-review-message">
          <ReviewIntroductionMessage reviewInfo={reviewInfo} />
          <UploadBankstatementLink />
        </Alert>
      );

    case 'received':
      return (
        <Alert type="success" className="c-review-message">
          <div>{I18n.nt(ns, 'received')}</div>
          <UploadBankstatementLink uploadMore />
        </Alert>
      );

    case 'expired': {
      const requestedStartDate = dateFormat(reviewInfo.requestedStartDate);
      const requestedEndDate = dateFormat(reviewInfo.requestedEndDate);

      return (
        <Alert type="danger" className="c-review-message">
          <div>
            {I18n.nt(
              ns,
              isAlfa(currentSeller) ? 'expired_1_alfa' : 'expired_1_charlie'
            )}{' '}
            {I18n.nt(ns, 'expired_2', { requestedStartDate, requestedEndDate })}
          </div>
          <UploadBankstatementLink />
        </Alert>
      );
    }

    case 'expiredAndReceived':
      return (
        <Alert type="danger" className="c-review-message">
          <div>{I18n.nt(ns, 'expired_and_received')}</div>
          <UploadBankstatementLink uploadMore />
        </Alert>
      );

    default:
      return null;
  }
};

export default ReviewWarning;

import React from 'react';
import { useSelector } from 'react-redux';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller } from 'types';
import CharlieSummary from './CharlieSummary';
import DeltaSummary from './DeltaSummary';
import LoanSummary from './LoanSummary';
import AlfaSummary from './AlfaSummary';
import EchoSummary from './EchoSummary';

const AccountSummary = () => {
  const seller = useSelector(currentSellerSelector) as MinimalSeller;

  if (seller.productVersion === 'delta') {
    return <DeltaSummary />;
  } else if (seller.hasLoan) {
    return <LoanSummary />;
  } else if (seller.productVersion === 'alfa') {
    return <AlfaSummary />;
  } else if (seller.productVersion === 'echo') {
    return <EchoSummary />;
  } else {
    return <CharlieSummary />;
  }
};

export default AccountSummary;

import React, { useRef } from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import usePayModal from './usePayModal';
import PayForm from './PayForm';
import ProcessingStep from './ProcessingStep';

const ns = 'charlie.frontpage.add_payment';

interface Props {
  parentPath: string;
}

const PayModal = ({ parentPath }: Props) => {
  const {
    close,
    companyName,
    complexAccount,
    gotoProcessingStep,
    step,
    thirdPartyPaymentId,
  } = usePayModal(parentPath);

  const amountInputRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      className="PayModal"
      initialFocus={amountInputRef}
      onHide={close}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        {complexAccount && <h5 className="subtitle">{companyName}</h5>}
      </Modal.Header>
      <Modal.Body>
        <div className="tw-mb-6 md:tw-mb-8">
          {step === 'form' && (
            <PayForm
              amountInputRef={amountInputRef}
              gotoProcessingStep={gotoProcessingStep}
            />
          )}
          {step === 'processing' && thirdPartyPaymentId && (
            <ProcessingStep thirdPartyPaymentId={thirdPartyPaymentId} />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PayModal;

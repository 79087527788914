import React from 'react';
import { Alert } from 'shared/bootstrap';
import { connect } from 'react-redux';

import * as I18n from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';

interface Props {
  rejectedWithdrawalAmount: number;
}

const RejectedWithdrawalWarning = ({ rejectedWithdrawalAmount }: Props) => {
  if (rejectedWithdrawalAmount > 0) {
    return (
      <Alert type="warning" className="RejectedWithdrawalWarning">
        <p>
          {I18n.nt(
            'charlie.frontpage.warnings',
            'rejected_withdrawal_warning',
            { amount: toEuro(rejectedWithdrawalAmount) }
          )}
        </p>
      </Alert>
    );
  }

  return null;
};

const mapStateToProps = (state) => ({
  rejectedWithdrawalAmount: parseFloat(
    state.charlie.dashboard.payload.rejectedWithdrawalAmount
  ),
});

export default connect(mapStateToProps)(RejectedWithdrawalWarning);

import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { goToPreviousStep } from 'signup/actions/SellerSignup';
import { UltimateBeneficialOwner } from 'types/UltimateBeneficialOwner';
import client from 'shared/utils/client';
import { updateUbosIdentification } from 'signup/actions/UboIdentification';

interface FormData {}

export type Action = '' | 'add' | 'edit';

const useUboIdentificationStep = () => {
  const dispatch = useAppDispatch();
  const [currentAction, setCurrentAction] = useState<Action>('');
  const [loadStatus, setLoadStatus] = useState<
    '' | 'loading' | 'loaded' | 'error'
  >('');
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);
  const [ubo, setUbo] = useState<UltimateBeneficialOwner | null>(null);
  const [ubos, setUbos] = useState<UltimateBeneficialOwner[]>([]);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const submit = async () => {
    const response = await dispatch(updateUbosIdentification());

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    }
  };

  const loadUbos = async () => {
    try {
      setLoadStatus('loading');
      const { payload: ubos } = await client(
        'GET',
        '/api/ultimate_beneficial_owners'
      );
      setUbos(ubos);
      setLoadStatus('loaded');
    } catch {
      setLoadStatus('error');
    }
  };

  const setActionAndUbo = (
    action: Action,
    ubo: UltimateBeneficialOwner | null
  ) => {
    setUbo(ubo);
    setCurrentAction(action);
  };

  const resetActionAndUbo = () => {
    setCurrentAction('');
    setUbo(null);
  };

  const addUboToState = (ubo: UltimateBeneficialOwner) => {
    setUbos([...ubos, ubo]);
    resetActionAndUbo();
  };

  const removeUboFromState = (ubo: UltimateBeneficialOwner) => {
    setUbos(ubos.filter((u) => u.id !== ubo.id));
    resetActionAndUbo();
  };

  const updateUboInState = (updatedUbo: UltimateBeneficialOwner) => {
    setUbos(
      ubos.map((ubo) => {
        if (ubo.id === updatedUbo.id) {
          return updatedUbo;
        } else {
          return ubo;
        }
      })
    );

    resetActionAndUbo();
  };

  useEffect(() => {
    loadUbos();
  }, []);

  return {
    addUboToState,
    control,
    currentAction,
    goToPreviousStep: () => dispatch(goToPreviousStep()),
    isSubmitting,
    loadStatus,
    removeUboFromState,
    resetActionAndUbo,
    serverErrorMessages,
    setActionAndUbo,
    submit: handleSubmit(submit),
    ubo,
    ubos,
    updateUboInState,
  };
};

export default useUboIdentificationStep;

import React from 'react';
import { Control } from 'react-hook-form';

import BankstatementUploadInput from 'hookForm/BankstatementUploadInput';
import * as AddStatementsStatus from './AddStatementsStatus';
import { WizardProps } from './loadWizard';

interface Props {
  reviewId: number;
  status: AddStatementsStatus.AddStatementsStatus;
  control: Control<WizardProps>;
}

export default ({ control, reviewId, status }: Props) => (
  <div className="bank-statement-upload">
    <BankstatementUploadInput
      control={control}
      model="review"
      modelId={reviewId}
      name="bankStatementAttachments"
      label=""
      readOnly={AddStatementsStatus.isUploading(status)}
    />
  </div>
);

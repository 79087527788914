import React from 'react';
import { TranslateOptions } from 'i18n-js';
import LoadingAnimation from 'shared/components/LoadingAnimation';
import * as I18n from 'shared/utils/I18n';
import usePoll from './usePoll';
import { ThirdPartyPaymentResponse } from './types';

const ns = 'charlie.frontpage.add_payment.processing_step';
const t = (key: string, options?: TranslateOptions) =>
  I18n.nt(ns, key, options);

interface Props {
  thirdPartyPaymentId: number;
}

const ProcessingStep = ({ thirdPartyPaymentId }: Props) => {
  const callback = (response: ThirdPartyPaymentResponse) => {
    const thirdPartyPayment = response.thirdPartyPayment;

    if (
      thirdPartyPayment.status === 'consent_pending' &&
      thirdPartyPayment.swanConsentUrl
    ) {
      location.href = thirdPartyPayment.swanConsentUrl;
      return false;
    }

    return true;
  };

  usePoll<ThirdPartyPaymentResponse>({
    path: `/api/third_party_payments/${thirdPartyPaymentId}`,
    timeMs: 500,
    callback,
  });

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-gap-6">
      <LoadingAnimation />
      <div className="tw-italic">{t('preparing_payment')}</div>
      <div>{t('next_step')}</div>
    </div>
  );
};

export default ProcessingStep;

import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { toEuro } from 'shared/utils/Helpers';
import useDeltaExtraPayOffModal from './useDeltaExtraPayOffModal';

const ns = 'delta.frontpage.extra_pay_off_modal';

interface Props {
  parentPath: string;
}

const DeltaExtraPayOffModal = ({ parentPath }: Props) => {
  const { florynIban, transferReference, remainingRepaymentAmount, onHide } =
    useDeltaExtraPayOffModal(parentPath);

  return (
    <Modal show onHide={onHide} className="c-extra-pay-off-modal">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{I18n.nt(ns, 'intro')}</p>
        <div className="well">
          <dl>
            <dt>{I18n.nt(ns, 'iban')}:</dt>
            <dd>{formatIban(florynIban)}</dd>
            <dt>{I18n.nt(ns, 'to_the_attention_of')}:</dt>
            <dd>{I18n.nt(ns, 'foundation_floryn_payments')}</dd>
            <dt>{I18n.nt(ns, 'description')}:</dt>
            <dd>
              {I18n.nt(ns, 'reference', { reference: transferReference })}
            </dd>
          </dl>
        </div>
        <p>
          {I18n.nt(ns, 'summary_html', {
            amount: toEuro(remainingRepaymentAmount),
          })}
        </p>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default DeltaExtraPayOffModal;

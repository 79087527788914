import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller, ReduxState } from 'types';
import { toEuro, toFloat } from 'shared/utils/Helpers';
import { getProduct } from 'models/Seller';
import useIsComplexAccount from 'shared/hooks/useIsComplexAccount';
import { nt } from 'shared/utils/I18n';

const ns = 'app.products';

export interface PayOffInfo {
  currentBalance: string | undefined;
  florynIban: string | undefined;
  maxRemainingExtraRepaymentAmount?: string;
}

const payOffSelector = (state: ReduxState): PayOffInfo => {
  return {
    currentBalance: state.charlie?.dashboard?.payload?.currentBalance,
    florynIban: state.charlie?.dashboard?.payload?.florynIban,
    maxRemainingExtraRepaymentAmount:
      state.charlie?.dashboard?.payload?.maxRemainingExtraRepaymentAmount,
  };
};

const useExtraPayOffModal = (parentPath) => {
  const history = useHistory();
  const { currentBalance, florynIban, maxRemainingExtraRepaymentAmount } =
    useSelectorWithShallowCompare(payOffSelector);
  const seller = useSelector(currentSellerSelector) as MinimalSeller;
  const currentBalanceEuro = toEuro(Math.abs(toFloat(currentBalance)));
  const onHide = () => {
    history.replace(parentPath);
  };

  const productName = nt(ns, getProduct(seller));
  let transferReference = seller.id.toString();
  transferReference += useIsComplexAccount() ? ` ${productName}` : '';

  return {
    currentBalance: currentBalanceEuro,
    florynIban,
    transferReference,
    maxRemainingExtraRepaymentAmount,
    onHide,
    seller,
  };
};

export default useExtraPayOffModal;

import React from 'react';
import { Glyphicon } from 'shared/bootstrap';

interface Props {
  status: 'ok' | 'warning' | 'pending';
  glyph?: string;
}

const Badge = ({ status, glyph }: Props) => {
  if (status === 'ok') {
    return (
      <div className="rw-badge rw-badge--ok">
        <Glyphicon glyph={glyph || 'ok'} />
      </div>
    );
  }

  if (status === 'warning') {
    return (
      <div className="rw-badge rw-badge--warning">
        <Glyphicon glyph={glyph || 'remove'} />
      </div>
    );
  }

  return <div className="rw-badge rw-badge--pending" />;
};

export default Badge;

import React from 'react';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller, ReduxState } from 'types';
import { formatIban } from 'shared/utils/Helpers';
import CurrentCommitmentPanel from './CurrentCommitmentPanel';
import NextDebtCollectionExplanation from './NextDebtCollectionExplanation';
import PendingSettlementPanel from './PendingSettlementPanel';
import InterestSummary from './InterestSummary';
import TransactionCosts from './TransactionCosts';

const DebtCollectionPanel = () => {
  const { dashboard, seller } = useSelectorWithShallowCompare(
    (state: ReduxState) => ({
      dashboard: state.charlie.dashboard.payload,
      seller: currentSellerSelector(state) as MinimalSeller,
    })
  );
  if (!dashboard) {
    return null;
  }

  const {
    baseInterestRate,
    currentCommitment,
    euriborRate,
    interestRate,
    latestInterestChange,
    nextDebtCollection,
    pendingSettlementDate,
    settlementCalculated,
    settlementToBeCalculated,
    transactionFeeRate,
  } = dashboard;
  const { clientBankAccount, productVersion } = seller;

  return (
    <div className="DebtCollectionPanel tw-mb-1 tw-flex tw-flex-col tw-items-end tw-gap-1 tw-font-semibold">
      {nextDebtCollection && (
        <NextDebtCollectionExplanation
          {...nextDebtCollection}
          settlementCalculated={settlementCalculated}
          settlementToBeCalculated={settlementToBeCalculated}
        />
      )}
      {!nextDebtCollection && pendingSettlementDate && (
        <PendingSettlementPanel pendingSettlementDate={pendingSettlementDate} />
      )}
      {clientBankAccount && (
        <div className="account tw-text-right">
          {formatIban(clientBankAccount)}
        </div>
      )}
      {currentCommitment && (
        <CurrentCommitmentPanel
          {...currentCommitment}
          settlementCalculated={settlementCalculated}
        />
      )}
      {productVersion === 'charlie' && (
        <InterestSummary
          baseInterestRate={baseInterestRate}
          euriborRate={euriborRate}
          interestRate={interestRate}
          latestInterestChange={latestInterestChange}
        />
      )}
      {productVersion === 'echo' && transactionFeeRate && (
        <TransactionCosts transactionFeeRate={transactionFeeRate} />
      )}
    </div>
  );
};

export default DebtCollectionPanel;

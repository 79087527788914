import React from 'react';
import AmountField from './AmountField';
import DebtCollectionPanel from './DebtCollectionPanel';

interface LabeledAmount {
  amount: number;
  translationKey: string;
}

interface Props {
  chartPercentage: number;
  renderBottomLeft: () => React.ReactNode;
  renderBottomRight: () => React.ReactNode;
  topLeft: LabeledAmount;
}

const DashboardSummaryLayout = ({
  chartPercentage,
  renderBottomLeft,
  renderBottomRight,
  topLeft,
}: Props) => (
  <div className="DashboardSummaryLayout">
    <div className="inner">
      <div className="top-panel">
        <div className="balance-panel">
          <AmountField
            amount={topLeft.amount}
            translationKey={topLeft.translationKey}
            includeColon={false}
          />
        </div>
        <DebtCollectionPanel />
      </div>
      <div className="chart">
        <div
          className="chart-fill"
          style={{
            transform: `scaleX(${chartPercentage})`,
          }}
        />
      </div>
      <div className="tw-grid tw-grid-cols-2">
        {renderBottomLeft()}
        {renderBottomRight()}
      </div>
    </div>
  </div>
);

export default DashboardSummaryLayout;

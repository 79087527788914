import { useSelector } from 'react-redux';
import { ReviewStatus } from 'types/Review';
import { ReduxState } from 'types';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import useReviewInfo from 'shared/hooks/useReviewInfo';

// Review status plus the expiredAndReceived status: the review is expired and a pdf is uploaded
type ReviewStatusForProduct = ReviewStatus | 'expiredAndReceived';

const useReviewStatusForProduct = (): ReviewStatusForProduct => {
  const seller = useCurrentSeller();
  const review = useReviewInfo();
  const canWithdraw = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.canWithdraw ?? false
  );

  if (!review) {
    return 'finished';
  }

  switch (review.status) {
    case 'finished':
    case 'open':
    case 'received':
      return review.status;

    case 'expired':
      if (seller.hasLoan || canWithdraw) {
        if (review.bankStatementAttachmentIds.length === 0) {
          return 'open';
        } else {
          return 'received';
        }
      } else {
        if (review.bankStatementAttachmentIds.length === 0) {
          return 'expired';
        } else {
          return 'expiredAndReceived';
        }
      }

    default:
      return 'finished';
  }
};

export default useReviewStatusForProduct;

import React, { useState } from 'react';
import Button from 'shared/components/tailwind/Button';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { formatIban } from 'shared/utils/Helpers';
import { usesClientBankAccount } from 'charlie/model';
import { toEuro } from 'shared/utils/Helpers';
import useRepayment from './useRepayment';
import LoadingAnimation from 'shared/components/LoadingAnimation';

const ns = 'charlie.frontpage.repayment_modal';

type ModalState = 'initial' | 'manual' | 'ideal';

interface Props {
  parentPath: string;
  amountInEuros: number;
}

interface IdealButtonProps {
  disabled: boolean;
  initiatePayment: () => Promise<void>;
}

const IdealButton = ({ disabled, initiatePayment }: IdealButtonProps) => (
  <div className="tw-mb-6 tw-flex tw-justify-end">
    <Button
      disabled={disabled}
      onClick={initiatePayment}
      className="btn-primary-inverted tw-flex tw-items-center tw-gap-3 tw-rounded-full tw-py-1 tw-pl-4 tw-pr-2"
    >
      {I18n.nt(ns, 'pay_with_ideal')}
      <img
        className="tw-w-[38px]"
        aria-label="ideal-logo"
        src="/images/ideal-logo-1024.png"
      />
    </Button>
  </div>
);

const RepaymentModal = ({ parentPath, amountInEuros }: Props) => {
  const {
    florynIban,
    transferReference,
    close,
    loadingStatus,
    initiatePayment,
    seller,
  } = useRepayment(parentPath);

  const [status, setStatus] = useState<ModalState>('initial');

  return (
    <Modal show onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'pay_back_arrear')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="tw-mb-5">
          {loadingStatus === 'error' && (
            <div className="tw-mb-6 tw-border tw-border-[#EBCCD1] tw-bg-[#F2DEDD] tw-p-4 tw-text-[#AA4442]">
              {I18n.nt(ns, 'ideal_error')}
            </div>
          )}
          {status === 'initial' && (
            <>
              <div className="tw-mb-5">
                {I18n.nt(ns, 'liable_message', {
                  liableAmount: toEuro(amountInEuros, 2),
                })}
              </div>
              <div className="tw-mb-7 tw-space-x-1">
                <span>{I18n.nt(ns, 'warning_message_part1')}</span>
                <a
                  className="tw-font-bold tw-text-black tw-underline hover:tw-cursor-pointer"
                  onClick={() => setStatus('manual')}
                >
                  {I18n.nt(ns, 'warning_message_part2')}
                </a>
                <span>{I18n.nt(ns, 'warning_message_part3')}</span>
              </div>
            </>
          )}
          {status === 'manual' && (
            <>
              <div className="tw-mb-5">
                {I18n.nt(ns, 'liable_message', {
                  liableAmount: toEuro(amountInEuros, 2),
                })}
              </div>
              <div className="tw-mb-7">
                <div>{I18n.nt(ns, 'warning_message_full')}</div>
              </div>
              <div className="tw-mb-5 tw-rounded-sm tw-border-2 tw-border-[#E3E3E3] tw-bg-[#F5F5F5] tw-p-3 sm:tw-p-5">
                <dl className="tw-mb-0 tw-flex tw-flex-wrap">
                  <dt className="tw-w-[37%] tw-font-normal sm:tw-w-[22%]">
                    {I18n.nt(ns, 'iban')}:
                  </dt>
                  <dd className="tw-w-[63%]	tw-font-bold sm:tw-w-[78%]">
                    {formatIban(
                      usesClientBankAccount(seller)
                        ? seller.clientBankAccount
                        : florynIban
                    )}
                  </dd>
                  <dt className="tw-w-[37%] tw-font-normal sm:tw-w-[22%]">
                    {I18n.nt(ns, 'to_the_attention_of')}:
                  </dt>
                  <dd className="tw-w-[63%]	tw-font-bold sm:tw-w-[78%]">
                    {usesClientBankAccount(seller)
                      ? seller.companyName
                      : I18n.nt(ns, 'foundation_floryn_payments')}
                  </dd>
                  <dt className="tw-w-[37%] tw-font-normal sm:tw-w-[22%]">
                    {I18n.nt(ns, 'description')}:
                  </dt>
                  <dd className="tw-w-[63%]	tw-font-bold sm:tw-w-[78%]">
                    {I18n.nt(ns, 'reference', { reference: transferReference })}
                  </dd>
                </dl>
              </div>
              <div>
                <div>{I18n.nt(ns, 'pay_manual_description')}</div>
              </div>
            </>
          )}
          <div>{loadingStatus === 'loading' && <LoadingAnimation />}</div>
          {status === 'initial' && (
            <IdealButton
              disabled={loadingStatus === 'loading'}
              initiatePayment={initiatePayment}
            />
          )}
          {status === 'manual' && (
            <IdealButton
              disabled={loadingStatus === 'loading'}
              initiatePayment={initiatePayment}
            />
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RepaymentModal;

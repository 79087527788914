import { useSelector } from 'react-redux';
import * as I18n from 'shared/utils/I18n';
import {
  validateAll,
  validateIban,
  validatePaymentReference,
  validatePresence,
} from 'shared/utils/validation';
import { toEuro } from 'shared/utils/Helpers';
import { cleanIban } from 'models/Iban';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { MinimalSeller } from 'types/Seller';
import { ReduxState } from 'types/ReduxState';
import { FormValues } from './types';

const MIN_AMOUNT = 100;
const MAX_AMOUNT = 25_000;

const ns = 'charlie.frontpage.add_payment';

const validateAvailableAmount =
  (availableWithdrawalAmount: number) => (amount: number) =>
    amount <= availableWithdrawalAmount ? null : I18n.nt(ns, 'cannot_withdraw');

const validateMinAmount = () => (amount: number) =>
  amount >= MIN_AMOUNT
    ? null
    : I18n.t('errors.messages.greater_than', {
        count: toEuro(MIN_AMOUNT) as any,
      });

const validateMaxAmount = () => (amount: number) =>
  amount <= MAX_AMOUNT
    ? null
    : I18n.t('errors.messages.less_than', { count: toEuro(MAX_AMOUNT) as any });

const validateAmount = (amount: number, availableWithdrawalAmount: number) =>
  validateAll(
    validatePresence,
    validateAvailableAmount(availableWithdrawalAmount),
    validateMaxAmount(),
    validateMinAmount()
  )(amount);

const useValidatePayForm = () => {
  const seller = useCurrentSeller() as MinimalSeller;
  const creditLimit = parseFloat(seller.creditLimit || '0');
  const pendingCurrentLimitFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.pendingCurrentLimit
  );
  const pendingCurrentLimit = parseFloat(pendingCurrentLimitFromState || '');
  const availableWithdrawalAmount = creditLimit - pendingCurrentLimit;

  const validate = ({
    amount,
    creditorIban,
    creditorName,
    paymentReference,
  }: FormValues) => {
    const cleanedIban = cleanIban(creditorIban || '');
    const cleanedPaymentReference = paymentReference?.replace(/[^0-9+]+/g, '');

    return {
      amount: validateAmount(amount, availableWithdrawalAmount),
      creditorIban: validateIban(cleanedIban),
      creditorName: validatePresence(creditorName),
      paymentReference: cleanedPaymentReference
        ? validatePaymentReference(cleanedPaymentReference)
        : null,
    };
  };

  return validate;
};

export default useValidatePayForm;

import * as Sentry from '@sentry/browser';

interface Params {
  sentryDSN?: string;
}

// Ignore errors thrown in extensions and certain external urls. For more info see:
// https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
const denyUrls = [
  /extensions\//i,
  /^chrome(-extension){0,1}:\/\//i,
  /bat\.bing\.com/i,
  /googletagmanager\.com/i,
  /gtm\.js/i,
];

const ignoreErrors = [
  // Network errors such as going offline or being blocked by a proxy
  'Failed to fetch',
];

const initSentry = ({ sentryDSN }: Params) => {
  if (!sentryDSN) {
    return;
  }

  const release =
    process.env.GIT_REVISION_HASH ||
    (window.APPLICATION_GLOBALS &&
      window.APPLICATION_GLOBALS.GIT_REVISION_HASH);

  Sentry.init({
    dsn: sentryDSN,
    release,
    denyUrls,
    ignoreErrors,
  });
};

export default initSentry;

import React, { RefObject } from 'react';
import { TranslateOptions } from 'i18n-js';
import { Button } from 'shared/bootstrap';
import CurrencyInput from 'hookForm/CurrencyInput';
import GeneralErrors from 'hookForm/GeneralErrors';
import * as I18n from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import TextInput from 'hookForm/TextInput';
import usePayForm from './usePayForm';
import PaySummary from './PaySummary';
import MaskedTextInput from 'hookForm/MaskedTextInput';

const ns = 'charlie.frontpage.add_payment';
const t = (key: string, options?: TranslateOptions) =>
  I18n.nt(ns, key, options);

interface Props {
  amountInputRef: RefObject<HTMLInputElement>;
  gotoProcessingStep: (thirdPartyPaymentId: number) => void;
}

const PayForm = ({ amountInputRef, gotoProcessingStep }: Props) => {
  const {
    availableBalance,
    control,
    generalErrors,
    isSubmitting,
    handleSubmit,
    summaryProperties,
    watch,
  } = usePayForm(gotoProcessingStep);

  const paymentReferenceValue = watch('paymentReference');
  const descriptionValue = watch('description');

  return (
    <div>
      <div>
        <form
          className="c-pay-invoice-modal-form"
          onSubmit={handleSubmit}
          autoComplete="off"
          noValidate
        >
          <GeneralErrors generalErrors={generalErrors} />
          <div id="currency-input">
            <CurrencyInput
              autoFocus
              control={control}
              hideLabel
              inputRef={amountInputRef}
              label={t('amount')}
              name="amount"
            />
          </div>
          <div className="tw-mb-6">
            {t('balance_info_html', {
              availableBalance: toEuro(availableBalance),
            })}
          </div>
          <TextInput
            control={control}
            label={t('name')}
            name="creditorName"
            placeholder={t('name_placeholder')}
          />
          <TextInput
            control={control}
            label={t('iban')}
            name="creditorIban"
            placeholder="NL12 FLRN 0000 1234 56"
          />
          <div className="tw-mb-6 tw-grid tw-gap-x-6 md:tw-grid-cols-2">
            <TextInput
              control={control}
              label={t('description')}
              maxLength={140}
              name="description"
              placeholder={t('description_placeholder')}
              readOnly={Boolean(paymentReferenceValue)}
            />
            <MaskedTextInput
              control={control}
              label={t('payment_reference')}
              mask="#### #### #### ####"
              name="paymentReference"
              placeholder={t('payment_reference_placeholder')}
              readOnly={Boolean(descriptionValue)}
            />
          </div>
        </form>
        <PaySummary summary={summaryProperties} />
      </div>
      <div className="tw-flex tw-justify-end">
        <Button
          className="btn-primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {I18n.nt(ns, 'submit')}
        </Button>
      </div>
      <div className="tw-mt-5 tw-flex tw-text-gray-600">
        <span className="icon icon-lock tw-mr-2 tw-mt-1" />
        <span>{t('next_step')}</span>
      </div>
    </div>
  );
};

export default PayForm;

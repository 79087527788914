import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import { OnboardingActionStatus } from 'types';
import Action, { ActionButton, ActionClosingModal } from '../Action';
import { nt } from '../utils';
import ExampleInvoicesModal from './ExampleInvoicesModal';

const t = nt('signup.onboarding.example_invoices');

interface Props {
  basePath: string;
  status: OnboardingActionStatus;
}

const ExampleInvoicesAction = ({ basePath, status }: Props) => {
  const history = useHistory();
  const closeModal = () => history.replace(basePath);
  const exampleInvoicesPath = `${basePath}/voorbeeldfacturen`;
  const renderModal = () => (
    <ActionClosingModal basePath={basePath}>
      <ExampleInvoicesModal onHide={closeModal} t={t} />
    </ActionClosingModal>
  );

  const Button = () => (
    <ActionButton to={exampleInvoicesPath}>{t('button')}</ActionButton>
  );

  return (
    <>
      <Action
        button={Button}
        description={t('description')}
        path={exampleInvoicesPath}
        status={status}
        title={t('title')}
      />
      <Route path={exampleInvoicesPath} render={renderModal} />
    </>
  );
};

export default ExampleInvoicesAction;

import React, { useState, MouseEvent } from 'react';
import Button from 'shared/components/tailwind/Button';
import GuestModal from 'shared/components/tailwind/GuestModal';
import useLogin from './useLogin';

const logins = [
  ['peterdijkstra@floryn.com', 'peterdijkstra@floryn.com (alfa)'],
  [
    'ais.connected@floryn.com',
    'ais.connected@floryn.com (charlie - onboarding)',
  ],
  [
    'charlie_with_service_fee@floryn.com',
    'charlie_with_service_fee@floryn.com (charlie - service fee)',
  ],
  [
    'client_bank_account@floryn.com',
    'client_bank_account@floryn.com (charlie - client bank account)',
  ],
  ['loan@loan.nl', 'loan@loan.nl (charlie - loan)'],
  ['delta@delta.nl', 'delta@delta.nl (delta)'],
];

interface Props {
  switchToNormalLogin: () => void;
}

const DevelopmentLogin = ({ switchToNormalLogin }: Props) => {
  const { login } = useLogin();
  const [email, setEmail] = useState(logins[0][0]);

  const submit = () => {
    login({ email, password: 'faux pas' });
  };

  const clickSwitchToNormalLogin = (e: MouseEvent) => {
    e.preventDefault();
    switchToNormalLogin();
  };

  return (
    <GuestModal>
      <div className="tw-flex tw-flex-col tw-gap-8">
        <h3 className="tw-mb-0 tw-text-lg md:tw-text-bootstrap-h3">
          Development login
        </h3>
        <div className="tw-flex tw-flex-col tw-gap-4">
          <select
            className="form-control"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          >
            {logins.map(([email, name]) => (
              <option key={email} value={email}>
                {name}
              </option>
            ))}
          </select>
          <div>
            <Button className="btn-primary" onClick={submit}>
              Log in
            </Button>
          </div>
        </div>
        <a href="#" onClick={clickSwitchToNormalLogin}>
          Of gebruik het normale loginformulier
        </a>
      </div>
    </GuestModal>
  );
};

export default DevelopmentLogin;

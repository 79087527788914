import React from 'react';
import EnsureTwoFactorAuthentication from 'app/components/seller/profile/TwoFactor/EnsureTwoFactorAuthentication';
import Insights from './Insights';
import EmptyPage from './Insights/EmptyPage';
import Layout from './Insights/Layout';
import EnsureNoComplexAccountModal from 'charlie/components/EnsureNoComplexAccountModal/EnsureNoComplexAccountModal';

const DisabledWrapper = ({ children }) => (
  <Layout>
    <EmptyPage>{children}</EmptyPage>
  </Layout>
);

const Dashboard = () => (
  <EnsureNoComplexAccountModal disabledWrapper={DisabledWrapper}>
    <EnsureTwoFactorAuthentication disabledWrapper={DisabledWrapper}>
      <Insights type="charlie" />
    </EnsureTwoFactorAuthentication>
  </EnsureNoComplexAccountModal>
);

export default Dashboard;

import React, { RefObject, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { isUndefined } from 'lodash';
import InputWrapper from './InputWrapper';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import BaseCurrencyInput from './elements/BaseCurrencyInput';
import ErrorMessage from './ErrorMessage';
import useSharedRef from './useSharedRef';

interface Props<TFieldValues extends FieldValues> {
  autoFocus?: boolean;
  control: Control<TFieldValues>;
  hideLabel?: boolean;
  hint?: string;
  inputRef?: RefObject<HTMLInputElement>;
  label: string;
  name: Path<TFieldValues>;
  onBlur?: (value: string) => void;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
}

const CurrencyInput = <TFieldValues extends FieldValues>({
  autoFocus,
  control,
  inputRef,
  hideLabel,
  hint,
  label,
  name,
  onBlur: onCustomBlur,
  placeholder,
  readOnly,
  required,
}: Props<TFieldValues>) => {
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error, invalid },
  } = useController<TFieldValues>({ control, name });

  const activeInputRef = useSharedRef(ref, inputRef);

  const [id] = useState(generateHtmlElementId);
  const [errorMessageId] = useState(generateHtmlElementId);
  const [hasFocus, setHasFocus] = useState(false);

  const handleBlur = () => {
    setHasFocus(false);
    onBlur();
    onCustomBlur && onCustomBlur(value);
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  return (
    <InputWrapper
      hideLabel={hideLabel}
      id={id}
      hint={hint}
      invalid={Boolean(error) && !hasFocus}
      label={label}
      name={name}
      required={required}
      type="currency"
    >
      <BaseCurrencyInput
        autoFocus={autoFocus}
        className="form-control"
        disabled={readOnly}
        errorMessageId={errorMessageId}
        id={id}
        inputRef={activeInputRef}
        invalid={invalid}
        name={name}
        numberOfDecimals={2}
        onBlur={handleBlur}
        onChange={onChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        value={isUndefined(value) ? '' : value}
      />
      <ErrorMessage error={error} id={errorMessageId} />
    </InputWrapper>
  );
};

export default CurrencyInput;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import resolver from 'hookForm/resolver';
import { parseISO } from 'date-fns';
import handleFormErrorsFromResponse from 'hookForm/handleFormErrorsFromResponse';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { cleanIban } from 'models/Iban';
import { MinimalSeller } from 'types/Seller';
import { ReduxState } from 'types/ReduxState';
import { calculateNewDebtCollectionAmount } from 'charlie/model';
import client from 'shared/utils/client';
import useValidatePayForm from './useValidatePayForm';
import { FormValues, ThirdPartyPaymentResponse } from './types';

const usePayForm = (
  gotoProcessingStep: (thirdPartyPaymentId: number) => void
) => {
  const seller = useCurrentSeller() as MinimalSeller;
  const [serverErrorMessages, setServerErrorMessages] = useState<string[]>([]);

  const validate = useValidatePayForm();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    setError,
    watch,
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const submit = async (paymentDetails: FormValues) => {
    const response = await client<ThirdPartyPaymentResponse>(
      'POST',
      '/api/third_party_payments',
      {
        amount: paymentDetails.amount,
        description: paymentDetails.description,
        creditorIban: cleanIban(paymentDetails.creditorIban),
        creditorName: paymentDetails.creditorName,
        paymentReference: paymentDetails.paymentReference,
        sellerId: seller.id,
      },
      { raiseError: false }
    );

    if (response.error) {
      const generalErrorMessages = handleFormErrorsFromResponse(
        response,
        setError
      );
      setServerErrorMessages(generalErrorMessages);
    } else {
      const thirdPartyPaymentId = response.payload.thirdPartyPayment.id;

      gotoProcessingStep(thirdPartyPaymentId);
    }
  };

  const amount = watch('amount') || 0.0;
  const creditorIban: string = watch('creditorIban') || '';
  const creditorName: string = watch('creditorName') || '';

  const availableBalanceFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.availableBalance
  );
  const availableBalance = parseFloat(availableBalanceFromState || '');

  const debtCollectionForNewWithdrawal = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionForNewWithdrawal
  );

  const debtCollectionRateFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.debtCollectionRate || ''
  );
  const debtCollectionRate = parseFloat(debtCollectionRateFromState || '');

  const pendingCurrentLimitFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.pendingCurrentLimit
  );
  const pendingCurrentLimit = parseFloat(pendingCurrentLimitFromState || '');

  const requestedAmountFromState = useSelector(
    (state: ReduxState) => state.charlie.dashboard.payload?.requestedAmount
  );
  const requestedAmount = parseFloat(requestedAmountFromState || '');

  const currentDebtCollectionAmount = debtCollectionForNewWithdrawal
    ? parseFloat(debtCollectionForNewWithdrawal?.amount)
    : 0;
  const debtCollectionDate = debtCollectionForNewWithdrawal
    ? parseISO(debtCollectionForNewWithdrawal.date)
    : undefined;
  const newDebtCollectionAmount = calculateNewDebtCollectionAmount({
    currentDebtCollectionAmount,
    debtCollectionRate: debtCollectionRate,
    pendingCurrentLimit: pendingCurrentLimit,
    requestedAmount: requestedAmount,
    withdrawAmount: amount,
  });

  const pendingCurrentBalanceFromState = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard.payload?.pendingCurrentBalance
  );
  const pendingCurrentBalance = parseFloat(
    pendingCurrentBalanceFromState || ''
  );

  const summaryProperties = {
    amount,
    debtCollectionDate,
    creditorIban,
    creditorName,
    newCurrentBalance: pendingCurrentBalance - amount,
    newDebtCollectionAmount,
  };

  return {
    availableBalance,
    control,
    generalErrors: serverErrorMessages,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    summaryProperties,
    watch,
  };
};

export default usePayForm;

import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useHistory, useRouteMatch } from 'react-router';
import * as I18n from 'shared/utils/I18n';
import { useCurrentSeller } from 'shared/hooks';
import { usesClientBankAccount } from 'charlie/model';
import ChevronDownSvg from 'svg/chevron_down.svg';
import ChevronUpSvg from 'svg/chevron_up.svg';
import useAutomaticWithdrawal from './useAutomaticWithdrawal';
import useCanWithdraw from './useCanWithdraw';

const ns = 'charlie.frontpage.actions';

const MenuItemComponent = ({ visible, onClick, labelKey }) => {
  if (!visible) return null;
  return (
    <Menu.Item as="div" onClick={onClick}>
      <button className="tw-w-full tw-py-3 tw-text-left tw-font-heading hover:tw-bg-floryn-tint-gray">
        <div className="tw-px-4 tw-font-semibold tw-text-floryn-bleen">
          {I18n.nt(ns, labelKey)}
        </div>
      </button>
    </Menu.Item>
  );
};

const CharlieDropdown = () => {
  const history = useHistory();
  const match = useRouteMatch();
  const seller = useCurrentSeller();
  const transactionsButtonVisible = usesClientBankAccount(seller);
  const { automaticWithdrawalVisible } = useAutomaticWithdrawal();
  const canWithdraw = useCanWithdraw();

  return (
    <Menu as="div" className="tw-relative tw-z-10">
      {({ open }) => (
        <>
          <Menu.Button className="btn btn-tertiary-inverted">
            <div className="tw-flex tw-items-center">
              {I18n.nt(ns, 'more')}
              {open ? (
                <ChevronUpSvg className="tw-ml-2 tw-h-3 tw-w-3 tw-fill-floryn-bleen" />
              ) : (
                <ChevronDownSvg className="tw-ml-2 tw-h-3 tw-w-3 tw-fill-floryn-bleen" />
              )}
            </div>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="tw-transition tw-ease-out tw-duration-100"
            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
            enterTo="tw-transform tw-opacity-100 tw-scale-100"
            leave="tw-transition tw-ease-in tw-duration-75"
            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
          >
            <Menu.Items className="tw-absolute tw-right-2 tw-w-60 tw-overflow-hidden tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-gray-200 focus:tw-outline-none">
              <MenuItemComponent
                visible={true}
                onClick={() => history.replace(`${match.path}/extra-aflossen`)}
                labelKey="extra_pay_off"
              />
              <MenuItemComponent
                visible={transactionsButtonVisible}
                onClick={() =>
                  history.replace(`${match.path}/transacties-downloaden`)
                }
                labelKey="export_transactions"
              />
              <MenuItemComponent
                visible={automaticWithdrawalVisible && canWithdraw}
                onClick={() =>
                  history.replace(`${match.path}/automatisch-opnemen`)
                }
                labelKey="automatic_withdrawal"
              />
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default CharlieDropdown;

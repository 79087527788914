import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import useWithdrawalDialog from './useWithdrawDialog';
import WithdrawDialogForm from './WithdrawDialogForm';

import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { isEnabled } from 'types/InstantPaymentAvailability';

const ns = 'charlie.frontpage.withdraw_dialog';

interface Props {
  parentPath: string;
}

const WithdrawDialog = ({ parentPath }: Props) => {
  const history = useHistory();
  const close = () => history.replace(parentPath);
  const {
    availableBalance,
    companyName,
    complexAccount,
    control,
    generalErrors,
    handleSubmit,
    isSubmitting,
    summaryProperties,
    withdrawAll,
  } = useWithdrawalDialog(close);

  const amountInputRef = useRef<HTMLInputElement>(null);

  return (
    <Modal
      className="WithdrawDialog"
      initialFocus={amountInputRef}
      onHide={close}
      show
    >
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        {complexAccount && <h5 className="subtitle">{companyName}</h5>}
      </Modal.Header>
      <Modal.Body>
        {isEnabled(summaryProperties.instantPayment) &&
        !summaryProperties.withdrawalWillRequireApproval ? (
          <div className="description">
            {I18n.nt(ns, 'instant_payment_description_html')}
          </div>
        ) : (
          <div className="description">{I18n.nt(ns, 'description_html')}</div>
        )}
        <WithdrawDialogForm
          amountInputRef={amountInputRef}
          availableBalance={availableBalance}
          control={control}
          generalErrors={generalErrors}
          handleSubmit={handleSubmit}
          summaryProperties={summaryProperties}
          withdrawAll={withdrawAll}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-primary"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {I18n.nt(ns, 'title')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawDialog;

import React from 'react';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { ReduxState } from 'types';
import { calculatePercentage } from './utils';
import AmountField from './AmountField';
import Settlement from './Settlement';
import SummaryLayout from './DashboardSummaryLayout';
import IconTooltip from 'shared/components/IconTooltip';
import { nt } from 'shared/utils/I18n';
import { dateFormat } from 'shared/utils/date';
import { toEuro } from 'shared/utils/Helpers';

const ns = 'charlie.frontpage.account_summary';

const CharlieSummary = () => {
  const { dashboard } = useSelectorWithShallowCompare((state: ReduxState) => ({
    dashboard: state.charlie.dashboard.payload,
  }));
  if (!dashboard) {
    return null;
  }

  const { availableBalance, creditLimit, currentBalance, nextRestriction } =
    dashboard;
  const settlement = parseFloat(dashboard.settlement);

  const bottomRight = {
    amount: parseFloat(creditLimit),
    translationKey: 'credit_limit',
  };

  const chartPercentage = calculatePercentage(
    parseFloat(availableBalance),
    parseFloat(creditLimit)
  );

  const topLeft = {
    amount: parseFloat(availableBalance),
    translationKey: 'available_balance',
  };

  const renderCurrentBalance = () => {
    if (settlement !== 0) {
      return <Settlement amount={settlement} />;
    } else {
      return (
        <div className="tw-flex tw-flex-col tw-gap-2">
          <AmountField
            amount={parseFloat(currentBalance)}
            translationKey="current_balance"
          />
        </div>
      );
    }
  };

  const renderBottomRight = () => {
    return (
      <div className="tw-flex tw-justify-end tw-gap-1 tw-text-right">
        <AmountField
          amount={bottomRight.amount}
          translationKey={bottomRight.translationKey}
        >
          {nextRestriction && (
            <span>
              <IconTooltip
                tooltipText={nt(ns, 'explain_restriction_html', {
                  date: dateFormat(nextRestriction.date),
                  new_credit_limit: toEuro(nextRestriction.newLimit),
                  step_amount: toEuro(nextRestriction.stepAmount),
                })}
                iconSize="sm"
              />
            </span>
          )}
        </AmountField>
      </div>
    );
  };

  return (
    <SummaryLayout
      chartPercentage={chartPercentage}
      renderBottomLeft={renderCurrentBalance}
      renderBottomRight={renderBottomRight}
      topLeft={topLeft}
    />
  );
};

export default CharlieSummary;

import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import client from 'shared/utils/client';
import { poll } from 'shared/utils/asyncUtils';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { loadDashboard } from 'charlie/modules/dashboardSlice';
import { PaymentStatus } from './PaymentStatus';

const useRepaymentStatus = (parentPath: string) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const seller = useCurrentSeller();
  const [status, setStatus] = useState<PaymentStatus>(PaymentStatus.OPEN);
  const { end_to_end_id } = useParams<{ end_to_end_id: string }>();

  useEffect(() => {
    if (end_to_end_id !== null) {
      pollStatus();
    }
  }, []);

  const pollStatus = async () => {
    try {
      const response = await poll(
        () => client('GET', `/api/ideal/status`, { end_to_end_id }),
        (payload) => payload.status !== PaymentStatus.OPEN
      );
      setStatus(response.status);
    } catch {
      setStatus(PaymentStatus.ERROR);
    }
  };

  const close = () => {
    // Update the dashboard to show any newly created mutations.
    dispatch(loadDashboard(seller.id));
    history.replace(parentPath);
  };
  const retry = () => history.replace('/klant/achterstand-terugbetalen');

  return {
    close,
    retry,
    status,
  };
};

export default useRepaymentStatus;

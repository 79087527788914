import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReduxState } from 'types';
import { Alert } from 'shared/bootstrap';
import ReviewIntroductionMessage from 'shared/components/ReviewMessages/ReviewIntroductionMessage';
import { useCurrentSeller } from 'shared/hooks';
import { dateFormat } from 'shared/utils/Helpers';
import * as I18n from 'shared/utils/I18n';
import { isAlfa, isCancelled } from 'models/Seller';
import useReviewInfo from 'shared/hooks/useReviewInfo';
import * as routes from 'app/routes';

import UploadBankstatementLink from './UploadBankstatementLink';
import useReviewStatusForProduct from 'charlie/components/ReactivationModal/useReviewStatusForProduct';

const ns = 'charlie.frontpage.warnings';

const ReviewMessage = (props) => {
  switch (props.status) {
    case 'finished':
      return null;

    case 'open':
      return (
        <>
          <ReviewIntroductionMessage reviewInfo={props.reviewInfo} />
          <UploadBankstatementLink />
        </>
      );

    case 'received':
      return (
        <>
          <div>{I18n.nt(ns, 'received')}</div>
          <UploadBankstatementLink uploadMore />
        </>
      );

    case 'expired': {
      const requestedStartDate = dateFormat(
        props.reviewInfo.requestedStartDate
      );
      const requestedEndDate = dateFormat(props.reviewInfo.requestedEndDate);

      return (
        <>
          <div>
            {I18n.nt(
              ns,
              isAlfa(props.seller) ? 'expired_1_alfa' : 'expired_1_charlie'
            )}{' '}
            {I18n.nt(ns, 'expired_2', { requestedStartDate, requestedEndDate })}
          </div>
          <UploadBankstatementLink />
        </>
      );
    }

    case 'expiredAndReceived':
      return (
        <>
          <div>{I18n.nt(ns, 'expired_and_received')}</div>
          <UploadBankstatementLink uploadMore />
        </>
      );

    default:
      return null;
  }
};

const DeliveryMessage = () => {
  return (
    <>
      <div>
        We zouden graag nog aanvullende informatie van je ontvangen. Deze kun je
        hier direct en veilig uploaden.
      </div>
      <div>
        <Link to={routes.PROFILE_PAGE_DELIVERIES}>
          Bekijk informatieverzoeken
        </Link>
      </div>
    </>
  );
};

function getAlertType(reviewStatus, hasActionableDeliveries) {
  if (reviewStatus === 'open') {
    return 'warning';
  } else if (reviewStatus === 'received' && !hasActionableDeliveries) {
    return 'success';
  } else if (reviewStatus === 'received' && hasActionableDeliveries) {
    return 'warning';
  } else if (
    reviewStatus === 'expired' ||
    reviewStatus === 'expiredAndReceived'
  ) {
    return 'danger';
  } else if (hasActionableDeliveries) {
    return 'warning';
  } else {
    return null;
  }
}

const DeliveryWarning = () => {
  const currentSeller = useCurrentSeller();
  const reviewStatusForProduct = useReviewStatusForProduct();
  const reviewInfo = useReviewInfo();
  const hasActionableDeliveries = useSelector(
    (state: ReduxState) =>
      state.charlie.dashboard?.payload?.hasCustomerActionableDeliveries ?? false
  );

  const alertType = getAlertType(
    reviewStatusForProduct,
    hasActionableDeliveries
  );

  if (isCancelled(currentSeller) || !alertType) {
    return null;
  }

  return (
    <Alert type={alertType} className="c-review-message">
      {reviewInfo && (
        <ReviewMessage
          status={reviewStatusForProduct}
          reviewInfo={reviewInfo}
          seller={currentSeller}
        />
      )}
      {hasActionableDeliveries && <DeliveryMessage />}
    </Alert>
  );
};

export default DeliveryWarning;

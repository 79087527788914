import { useState } from 'react';
import * as tracker from 'shared/utils/tracker';
import client from 'shared/utils/client';
import { isApproved } from 'models/Seller';
import { Seller } from 'types';
import { State } from './types';

interface Params {
  onRefresh: (bank: string, redirectUrl: string) => void;
  redirectPath: string;
  seller: Seller;
}

const usePsd2Banks = ({ onRefresh, redirectPath, seller }: Params) => {
  const [bank, setBank] = useState<string | null>(null);
  const [state, setState] = useState<State>('');

  const resetBank = () => {
    setBank(null);
  };

  const chooseBank = (bank: string) => {
    tracker.trackEvent('Select bank for psd2', { bank });
    setBank(bank);
  };

  const redirect = () => {
    if (!bank) {
      return;
    }

    onRefresh(bank, redirectPath);
  };

  const updateSellerAndRedirect = async () => {
    if (!bank) {
      return;
    }

    const response = await client(
      'PUT',
      `/api/sellers/${seller.id}`,
      {
        ais_initiated_at: new Date(),
      },
      { raiseError: false }
    );

    if (response.error) {
      setState('error');
    } else {
      redirect();
    }
  };

  const redirectToBank = () => {
    if (state === 'redirecting' || !bank) {
      return;
    }

    setState('redirecting');

    tracker.trackDocumentsComplete('psd2', bank, () => {
      if (!isApproved(seller)) {
        updateSellerAndRedirect();
      } else {
        redirect();
      }
    });
  };

  return { bank, chooseBank, redirectToBank, resetBank, state };
};

export default usePsd2Banks;

import { useState } from 'react';

/* Hook for a closable alert.

* Can be dismissed (dismiss method) and is not displayed again (closed variable).
* The state is store in local storage.
* Use a unique key (localStorageKey parameter).
*/
const useClosableAlert = (localStorageKey: string) => {
  const [closed, setClosed] = useState(false);

  const markAsClosed = () => {
    localStorage?.setItem(localStorageKey, '1');
  };

  const dismiss = () => {
    setClosed(true);
    markAsClosed();
  };

  const markedAsClosed = localStorage?.getItem(localStorageKey);

  return {
    closed: closed || markedAsClosed,
    dismiss,
    markAsClosed,
  };
};

export default useClosableAlert;

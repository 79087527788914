import React, { useEffect, useRef } from 'react';
import { Button, Modal } from 'shared/bootstrap';
import { toEuro } from 'shared/utils/Helpers';
import WithdrawalSummary from 'shared/components/WithdrawalSummary';
import CurrencyInput from 'hookForm/CurrencyInput';
import GeneralErrors from 'hookForm/GeneralErrors';
import { nt } from '../utils';
import useWithdrawal from './useWithdrawal';
import { notEnabled } from 'types/InstantPaymentAvailability';

const t = nt('signup.onboarding.withdrawal');

interface Props {
  basePath: string;
}

const WithdrawDialog = ({ basePath }: Props) => {
  const {
    amount,
    conditions,
    control,
    hasWithdrawal,
    isSubmitting,
    newDebtCollectionAmount,
    serverErrorMessages,
    submit,
    withdrawAll,
  } = useWithdrawal(basePath);

  const amountInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    amountInputRef.current?.focus();
  }, []);

  return (
    <form className="c-withdraw-dialog-form" onSubmit={submit}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title.without_withdrawal')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t('leader')}</p>
        <GeneralErrors generalErrors={serverErrorMessages} />
        <CurrencyInput
          autoFocus
          control={control}
          name="amount"
          inputRef={amountInputRef}
          label={t('amount')}
        />

        <div className="balance-panel">
          <div className="balance-description">
            {t('credit_limit', { amount: toEuro(conditions.creditLimit) })}
          </div>
          <div>
            <Button
              onClick={withdrawAll}
              className="btn-sm btn-tertiary-inverted btn-signup"
            >
              {t('withdraw_all')}
            </Button>
          </div>
        </div>

        <WithdrawalSummary
          amount={amount}
          currentDebtCollectionAmount={0}
          newDebtCollectionAmount={newDebtCollectionAmount}
          instantPayment={notEnabled()}
          {...conditions}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button type="submit" className="btn-primary" disabled={isSubmitting}>
          {hasWithdrawal
            ? t('button.with_withdrawal')
            : t('button.without_withdrawal')}
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default WithdrawDialog;

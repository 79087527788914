import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Review, ReduxState } from 'types';
import { BankStatementAttachment } from 'types/Attachment';
import resolver from 'hookForm/resolver';
import useAppDispatch from 'shared/hooks/useAppDispatch';
import { validatePresence } from 'shared/utils/validation';
import { useCurrentSeller } from 'shared/hooks';
import { PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW } from 'app/routes';
import { updateBankStatements } from 'PartnerSignup/modules/partnerSignupSlice';
import { trackFormSubmit } from 'shared/utils/tracker';

interface DocumentUploadFormData {
  bankStatementAttachments: BankStatementAttachment[];
}

const validate = ({ bankStatementAttachments }: DocumentUploadFormData) => ({
  bankStatementAttachments: validatePresence(bankStatementAttachments),
});

const isValidAttachment = (attachment: BankStatementAttachment) =>
  attachment?.diagnostics?.type !== 'warning';

const useDocumentUpload = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const review = useSelector(
    (state: ReduxState) => state.partnerSignup.review as Review
  );
  const seller = useCurrentSeller();
  const [uploading, setUploading] = useState(false);
  const [updatingAttachments, setUpdatingAttachments] = useState(false);

  const defaultAttachments = useSelector(
    (state: ReduxState) => state.partnerSignup.bankStatementAttachments
  );
  const {
    control,
    formState: { isSubmitting, touchedFields },
    handleSubmit,
    setValue,
  } = useForm<DocumentUploadFormData>({
    defaultValues: {
      bankStatementAttachments: defaultAttachments,
    },
    resolver: resolver(validate),
  });
  const bankStatementAttachments = useWatch({
    control,
    name: 'bankStatementAttachments',
  });

  const attachmentsWithWarning = bankStatementAttachments.filter(
    (attachment) => !isValidAttachment(attachment as BankStatementAttachment)
  );

  const updateAttachments = async () => {
    setUpdatingAttachments(true);

    const attachmentIds = bankStatementAttachments.map(
      (attachment) => attachment.id
    );
    await dispatch(updateBankStatements({ attachmentIds }));

    setUpdatingAttachments(false);
  };

  useEffect(() => {
    if (touchedFields.bankStatementAttachments) {
      updateAttachments();
    }
  }, [bankStatementAttachments]);

  const removeInvalidAttachments = () => {
    setValue(
      'bankStatementAttachments',
      bankStatementAttachments.filter((attachment) =>
        isValidAttachment(attachment as BankStatementAttachment)
      ),
      { shouldTouch: true, shouldDirty: true }
    );
  };

  const submit = () => {
    trackFormSubmit('partnersignup-bankstatement-upload');
    history.push(PARTNER_SIGNUP_BANK_STATEMENTS_OVERVIEW + '?status=success');
  };

  const saving = uploading || updatingAttachments || isSubmitting;

  const warnings =
    attachmentsWithWarning.length !== 0 ||
    review.missingPeriods.length > 0 ||
    false;

  return {
    attachmentsWithWarning,
    control,
    removeInvalidAttachments,
    review,
    saving,
    seller,
    setUploading,
    submit: handleSubmit(submit),
    warnings,
  };
};

export default useDocumentUpload;

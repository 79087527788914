import React from 'react';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { toEuro, formatIban } from 'shared/utils/Helpers';
import { usesClientBankAccount } from 'charlie/model';
import useExtraPayOffModal from './useExtraPayOffModal';

const ns = 'charlie.frontpage.extra_pay_off_modal';

interface Props {
  parentPath: string;
}

const ExtraPayOffModal = ({ parentPath }: Props) => {
  const {
    currentBalance,
    florynIban,
    transferReference,
    maxRemainingExtraRepaymentAmount,
    onHide,
    seller,
  } = useExtraPayOffModal(parentPath);

  return (
    <Modal show onHide={onHide} className="c-extra-pay-off-modal">
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{I18n.nt(ns, 'intro')}</p>
        <div className="well">
          <dl>
            <dt>{I18n.nt(ns, 'iban')}:</dt>
            <dd>
              {formatIban(
                usesClientBankAccount(seller)
                  ? seller.clientBankAccount
                  : florynIban
              )}
            </dd>
            <dt>{I18n.nt(ns, 'to_the_attention_of')}:</dt>
            <dd>
              {usesClientBankAccount(seller)
                ? seller.companyName
                : I18n.nt(ns, 'foundation_floryn_payments')}
            </dd>
            <dt>{I18n.nt(ns, 'description')}:</dt>
            <dd>
              {I18n.nt(ns, 'reference', { reference: transferReference })}
            </dd>
          </dl>
        </div>
        <p>
          {seller.hasLoan
            ? I18n.nt(ns, 'summary_with_loan_product_html', {
                amount: currentBalance,
                max: toEuro(maxRemainingExtraRepaymentAmount),
              })
            : I18n.nt(ns, 'summary_with_credit_product_html', {
                amount: currentBalance,
              })}
        </p>
        {!seller.hasLoan &&
          !usesClientBankAccount(seller) &&
          I18n.nt(ns, 'summary_with_credit_product_and_debt_collection_html')}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default ExtraPayOffModal;

import React from 'react';
import { Alert } from 'shared/bootstrap';
import type { AlertType } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import { toEuro } from 'shared/utils/Helpers';
import useSelectorWithShallowCompare from 'shared/hooks/useSelectorWithShallowCompare';
import { currentSellerSelector } from 'shared/selectors';
import { MinimalSeller, ReduxState } from 'types';
import LiableButton from './LiableButton';

const ns = 'charlie.frontpage.warnings';

const mapStateToProps = (state: ReduxState) => {
  const dashboardState = state.charlie.dashboard.payload;

  const getDebtCollectionAmount = () => {
    if (dashboardState?.nextDebtCollection) {
      return parseFloat(dashboardState.nextDebtCollection.amount);
    }

    if (dashboardState?.currentCommitment) {
      return parseFloat(dashboardState.currentCommitment.amount);
    }

    return 0;
  };

  const debtCollectionAmount = getDebtCollectionAmount();
  const liableAmount = parseFloat(dashboardState?.liable ?? '0');
  const idealEnabled = dashboardState?.idealEnabled ?? false;
  const { hasLoan } = currentSellerSelector(state) as MinimalSeller;

  const getMessageLevelAndKey = (): [string, string] => {
    if (liableAmount <= 0) {
      return ['', ''];
    }

    const reversalCount =
      debtCollectionAmount === 0 ? 0 : liableAmount / debtCollectionAmount;

    if (reversalCount <= 2.0) {
      return ['warning', 'liable_message'];
    } else if (reversalCount <= 4.0) {
      return [
        'danger',
        hasLoan
          ? 'liable_message_loan_more_than_two_times'
          : 'liable_message_credit_more_than_two_times',
      ];
    } else {
      // The 'more than four times' message doesn't apply to the loan product because the product doesn't allow withdrawals.
      // Instead display the 'more than two times' message.

      return [
        'danger',
        hasLoan
          ? 'liable_message_loan_more_than_two_times'
          : 'liable_message_credit_more_than_four_times',
      ];
    }
  };

  const [messageLevel, messageKey] = getMessageLevelAndKey();

  return {
    debtCollectionAmount,
    liable: dashboardState?.liable ?? '0',
    messageKey,
    messageLevel,
    idealEnabled,
  };
};

const LiableWarning = () => {
  const {
    debtCollectionAmount,
    liable,
    messageKey,
    messageLevel,
    idealEnabled,
  } = useSelectorWithShallowCompare(mapStateToProps);

  if (!messageLevel) {
    return null;
  }

  return (
    <Alert type={messageLevel as AlertType}>
      {I18n.nt(ns, messageKey, {
        doubleDebtCollectionAmount: toEuro(debtCollectionAmount * 2),
        debtCollectionAmountTimes4: toEuro(debtCollectionAmount * 4),
        liableAmount: toEuro(liable),
      })}
      {idealEnabled &&
        parseFloat(liable) <= 50000 &&
        parseFloat(liable) >= 1 && (
          <div className="tw-mt-4">
            <LiableButton />
          </div>
        )}
    </Alert>
  );
};

export default LiableWarning;

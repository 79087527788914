import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { compilePath } from 'shared/utils/routing';
import { INVOICE_TAB } from 'app/routes';

import { companySelector } from 'shared/selectors';
import { actionCreators } from './module';
import { ReduxState } from 'types';
import { isCancelled } from 'models/Seller';
import useSelectInvoice from 'shared/hooks/useSelectInvoice';

interface RouteParams {
  id: string;
  tabKey: string;
}

const useInvoice = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: invoiceIdParam, tabKey: initialTab } = useParams<RouteParams>();
  const [tabKey, setTabKey] = useState(initialTab || 'overzicht');

  const seller = useCurrentSeller();
  const sellerId = seller?.id;

  const loadInvoiceSeller = () => {
    const invoiceId = parseInt(invoiceIdParam);
    if (!isFinite(invoiceId) || !isFinite(sellerId)) {
      return;
    }

    dispatch(actionCreators.loadInvoiceById(invoiceId));
    dispatch(actionCreators.loadSellerById(sellerId));
  };

  useEffect(() => {
    loadInvoiceSeller();
  }, [invoiceIdParam]);

  const invoice = useSelectInvoice(invoiceIdParam);

  const { invoiceLoading, performingAction, sellerLoading, showSubmitSummary } =
    useSelector((state: ReduxState) => state.app.invoice);

  const company = useSelector(companySelector);

  const invoiceContactsMap = useSelector(
    (state: ReduxState) => state.entities.invoiceContacts
  );

  const onInvoiceAction = (invoiceAction, params = {}) => {
    dispatch(actionCreators.invoiceAction(invoice, invoiceAction, params)).then(
      (result) => {
        if (invoiceAction === 'submit' && !result.error) {
          window.scrollTo(0, 0);
        }
      }
    );
  };

  const onRequestFinancing = () => {
    dispatch(actionCreators.requestFinancing(invoice?.id));
  };

  const tabSelect = (newTabKey: string) => {
    history.push(
      compilePath(INVOICE_TAB, { id: invoiceIdParam, tabKey: newTabKey })
    );
    setTabKey(newTabKey);
  };

  const readonly = isCancelled(seller);

  return {
    company,
    initialTab,
    invoice,
    invoiceContactsMap,
    invoiceIdParam,
    invoiceLoading,
    onInvoiceAction,
    onRequestFinancing,
    performingAction,
    readonly,
    seller,
    sellerLoading,
    showSubmitSummary,
    tabKey,
    tabSelect,
  };
};

export default useInvoice;

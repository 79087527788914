import React from 'react';
import CurrencyInput from 'hookForm/CurrencyInput';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import useAutomaticWithdrawalModal from './useAutomaticWithdrawalModal';
import Button from 'shared/components/tailwind/Button';
import { toEuro } from 'namespaces/shared/utils/Helpers';

const ns = 'charlie.frontpage.automatic_withdraw_modal';

interface Props {
  parentPath: string;
}

const AutomaticWithdrawalModal = ({ parentPath }: Props) => {
  const {
    automaticWithdrawalActivated,
    control,
    error,
    fixedAutomaticWithdrawalLimit,
    handleSubmit,
    isSubmitting,
    onHide,
    setTermsAccepted,
    termsAccepted,
    updateAutomaticWithdrawal,
  } = useAutomaticWithdrawalModal(parentPath);

  return (
    <Modal show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && (
          <div className="alert alert-danger">{I18n.nt(ns, 'error')}</div>
        )}
        {automaticWithdrawalActivated ? (
          <div className="alert alert-success">{I18n.nt(ns, 'active')}</div>
        ) : (
          <div className="alert alert-warning">{I18n.nt(ns, 'inactive')}</div>
        )}
        <p className="tw-mb-6">
          {I18n.nt(ns, 'intro', {
            amount: toEuro(fixedAutomaticWithdrawalLimit),
          })}
        </p>
        {automaticWithdrawalActivated && (
          <p className="tw-mb-6">
            {I18n.nt(ns, 'current_set_limit', {
              amount: toEuro(fixedAutomaticWithdrawalLimit),
            })}
          </p>
        )}
        {!automaticWithdrawalActivated && (
          <div id="currency-input">
            <div className="c-withdraw-dialog-form">
              <CurrencyInput
                autoFocus
                control={control}
                label={I18n.nt(ns, 'limit_automatic_withdrawal')}
                name="amount"
              />
            </div>
          </div>
        )}
        <div className="well tw-mb-0">
          <p>{I18n.nt(ns, 'summary')}</p>
          <ul>
            <li>{I18n.nt(ns, 'bullet_point_one')}</li>
            <li>{I18n.nt(ns, 'bullet_point_two')}</li>
            <li>{I18n.nt(ns, 'bullet_point_three')}</li>
          </ul>
          <p> {I18n.nt(ns, 'disableable')}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {automaticWithdrawalActivated ? (
          <Button
            onClick={handleSubmit(updateAutomaticWithdrawal)}
            className="btn-primary"
            disabled={isSubmitting}
          >
            {I18n.nt(ns, 'deactivate')}
          </Button>
        ) : (
          <>
            <div className="tw-mb-4 tw-flex">
              <div
                className="tw-ml-auto tw-flex"
                onClick={() => {
                  setTermsAccepted(!termsAccepted);
                }}
              >
                <input
                  checked={termsAccepted}
                  className="tw-mr-2 tw-h-4 tw-w-4 tw-cursor-pointer"
                  type="checkbox"
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />
                <p className="tw-mb-0 tw-ml-2 tw-cursor-pointer">
                  {I18n.nt(ns, 'terms')}
                </p>
              </div>
            </div>
            <Button
              onClick={handleSubmit(updateAutomaticWithdrawal)}
              className="btn-primary"
              disabled={isSubmitting || !termsAccepted}
            >
              {I18n.nt(ns, 'activate')}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AutomaticWithdrawalModal;

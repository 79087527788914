import React from 'react';
import classNames from 'classnames';
import { Uploader, DeliveryRequestItem, DeliveryRequest } from './types';
import { translate } from 'shared/utils/I18n';
import { dateFormat, simpleFormat } from 'shared/utils/Helpers';
import FileList from './FileList';
import StreamlineIcon from './StreamlineIcon';
import { capitalizeFirstLetter } from 'shared/utils/Helpers';

const t = translate('deliveries_page.delivery_panel');

interface Props extends DeliveryRequest {
  onDelete: (file: DeliveryRequestItem) => void;
  onUpload: Uploader;
  multipleCompanies: boolean;
}

export function documentTypes(types: string[]) {
  const names = types.map((type) => t(`document_types.${type}`));
  if (names.length > 1) {
    const last = names.pop();
    return `${names.join(', ')} en ${last}`;
  } else {
    return names[0];
  }
}

const DeliveryPanelTitle = (props: Props) => {
  if (props.title) {
    return <span>{props.title}</span>;
  }
  return (
    <>
      <strong>
        {capitalizeFirstLetter(dateFormat(props.plannedAt, 'month_year'))}
      </strong>
      : <span>{documentTypes(props.requestTypes)}</span>
    </>
  );
};

const DeliveryPanel = (props: Props) => {
  const isPlanned = props.status === 'planned';
  const hasIntro = !isPlanned && props.introduction;
  const isOpen = props.status === 'open';
  const isAccepted = props.status === 'accepted';
  const isReceived = props.status === 'received';
  const hasFeedback = props.status === 'rejected';
  const isExpired = props.status === 'expired';

  const handleUpload = (file: File) => {
    return props.onUpload(props.id, file);
  };

  let dateLine;
  switch (props.status) {
    case 'expired':
      dateLine = t('expired', {
        date: dateFormat(props.currentDecisionCreatedAt),
      });
      break;
    case 'planned':
      dateLine = t('planned', {
        date: props.plannedAt ? dateFormat(props.plannedAt) : '',
      });
      break;
    case 'received':
      dateLine = t('received', {
        date: dateFormat(props.currentDecisionCreatedAt),
      });
      break;
    case 'accepted':
      dateLine = t('accepted', {
        date: dateFormat(props.currentDecisionCreatedAt),
      });
      break;
    case 'rejected':
    case 'open':
      dateLine = t('deadline', { date: dateFormat(props.deadline) });
      break;
  }
  return (
    <div
      data-testid="delivery-panel"
      className={classNames(
        'tw-rounded-md tw-border tw-border-t-0 tw-shadow-lg',
        {
          'tw-border-green-500': isOpen || isReceived,
          'tw-border-amber-400': hasFeedback || isExpired,
        }
      )}
    >
      <div
        className={classNames('tw-space-y-6 tw-rounded-t-md tw-p-6', {
          'tw-rounded-b-md tw-bg-gray-50': isPlanned,
          'tw-border-t-8 tw-border-gray-400 tw-bg-gray-50': isAccepted,
          'tw-border-t-8 tw-border-green-500': isOpen || isReceived,
          'tw-border-t-8 tw-border-amber-400': hasFeedback || isExpired,
        })}
      >
        <div className="tw-items-center md:tw-flex md:tw-gap-4">
          <h4
            className={classNames('tw-m-0 tw-flex-1 tw-font-medium', {
              'tw-text-gray-500': isPlanned || isAccepted,
              'tw-text-green-600': isOpen || isReceived,
              'tw-text-amber-600': hasFeedback || isExpired,
            })}
          >
            <DeliveryPanelTitle {...props} />
          </h4>
          {props.multipleCompanies && (
            <div
              data-testid="delivery-panel__company-name"
              className={classNames('tw-text-sm', {
                'tw-text-green-500': isOpen || isReceived,
                'tw-text-gray-500': isPlanned || isAccepted,
                'tw-text-amber-500': hasFeedback || isExpired,
              })}
            >
              {props.companyName}
            </div>
          )}
          <div
            data-testid="delivery-panel__date-line"
            className={classNames('tw-text-sm', {
              'tw-text-green-500': isOpen || isReceived,
              'tw-text-gray-500': isPlanned || isAccepted,
              'tw-text-amber-500': hasFeedback || isExpired,
            })}
          >
            {dateLine}
          </div>
        </div>
        {!isAccepted && hasIntro && props.introduction && (
          <div
            className="tw-max-w-prose"
            dangerouslySetInnerHTML={{
              __html: simpleFormat(props.introduction),
            }}
          />
        )}
      </div>
      {isPlanned || (
        <div className="tw-space-y-6">
          {!isAccepted && props.customerFeedback && (
            <div className="tw-space-y-6 tw-px-6">
              <hr style={{ marginTop: 0 }} />
              <div className="tw-max-w-prose tw-space-y-6">
                <div className="tw-flex tw-items-start tw-gap-3 md:tw-gap-6">
                  <div className="tw-inline-flex tw-h-6 tw-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-amber-300 md:tw-h-10 md:tw-w-10">
                    <StreamlineIcon
                      icon="Messages Bubble Typing 1"
                      strokeWidth={2}
                      className="tw-h-6 tw-w-6 tw-text-yellow-900"
                    />
                  </div>
                  <div className="tw-flex-1 tw-space-y-1 tw-text-base">
                    <p className="tw-m-0">{props.customerFeedback}</p>
                    <div className="tw-text-sm tw-text-gray-500">
                      {t('feedback_at', {
                        date: dateFormat(props.currentDecisionCreatedAt),
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <FileList
            items={props.items}
            onDelete={props.onDelete}
            onUpload={handleUpload}
            disabled={!(isOpen || isReceived || hasFeedback) || isAccepted}
          />
        </div>
      )}
    </div>
  );
};

export default DeliveryPanel;

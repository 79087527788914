import React from 'react';
import ShadowBox from 'shared/components/tailwind/ShadowBox';
import { nt } from 'shared/utils/I18n';
import ChevronRighthSvg from 'svg/chevron_right.svg';
import GoogleSvg from 'svg/google.svg';

const ns = 'signup.onboarding.google_reviews';

const GoogleReviews = () => (
  <ShadowBox className="tw-flex tw-flex-col tw-justify-between tw-gap-5 tw-p-4 sm:tw-pb-5 sm:tw-pl-6 sm:tw-pr-8 sm:tw-pt-6">
    <div className="tw-flex tw-items-center tw-gap-4">
      <div>{nt(ns, 'intro')}</div>
      <div className="tw-flex tw-flex-col tw-items-center tw-gap-2">
        <GoogleSvg className="tw-h-12 tw-w-12" />
        <span className="tw-h-[11.4px] tw-w-[68px] tw-bg-[url('/images/star_inactive.svg')] tw-bg-[length:14px_11.4px]">
          <span className="tw-block tw-h-[11.4px] tw-w-[62px] tw-bg-[url('/images/star_active.svg')] tw-bg-[length:14px_11.4px]" />
        </span>
      </div>
    </div>
    <a
      className="tw-flex tw-items-center tw-gap-2 tw-font-bold tw-text-floryn-green"
      href="https://www.google.com/maps?cid=139359605008204356"
      target="_blank"
    >
      <span>{nt(ns, 'link')}</span>
      <ChevronRighthSvg className="tw-h-3 tw-w-3" />
    </a>
  </ShadowBox>
);

export default GoogleReviews;

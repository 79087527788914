import React from 'react';
import { Menu } from '@headlessui/react';

interface DropDownItem {
  eventKey: any;
  disabled: boolean;
}

interface DropdownButtonProps {
  id: string;
  title?: string;
  className?: string;
  disabled?: boolean;
  datatestid?: string;
  items: DropDownItem[];
  renderItem: (any) => React.ReactNode;
  onSelect: (any) => void;
}

const DropdownButton = ({
  title,
  items,
  renderItem,
  onSelect,
}: DropdownButtonProps) => {
  return (
    <div className="tw-relative">
      <Menu>
        <Menu.Button
          className={
            'tw-text-md tw-rounded-full tw-border-[2px] tw-border-gray-400 tw-px-4 tw-py-1.5 tw-text-black hover:tw-bg-gray-300 focus:tw-bg-gray-300'
          }
        >
          <div className="tw-flex tw-items-center">
            <div className="tw-mr-1 tw-flex">{title}</div>
            <span className="caret"></span>
          </div>
          <span></span>
        </Menu.Button>
        <Menu.Items className="tw-absolute tw-left-0 tw-top-full tw-z-10 tw-border tw-border-gray-300 tw-bg-white tw-shadow">
          {items.map((item, id) => (
            <Menu.Item key={id} disabled={item.disabled}>
              {() => (
                <a
                  className="tw-cursor-pointer tw-no-underline hover:tw-no-underline"
                  onClick={() => onSelect(item.eventKey)}
                >
                  <div className="tw-w-80 tw-border tw-border-0 tw-border-gray-300 tw-px-5 tw-py-2 tw-shadow hover:tw-bg-gray-200">
                    {renderItem(item.eventKey)}
                  </div>
                </a>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default DropdownButton;

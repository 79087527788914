import React, { ReactNode } from 'react';

type AlertType = 'danger' | 'info' | 'success' | 'warning';

interface Props {
  children?: ReactNode;
  closeLabel?: string;
  onDismiss?: () => void;
  type: AlertType;
  style?: React.CSSProperties;
  className?: string;
}

const Alert = ({
  children,
  className,
  closeLabel,
  onDismiss,
  type,
  ...props
}: Props) => (
  <div
    role="alert"
    className={['alert', `alert-${type}`, className].join(' ')}
    {...props}
  >
    {Boolean(onDismiss) && (
      <button type="button" className="close" onClick={onDismiss}>
        <span aria-hidden="true">×</span>
        <span className="sr-only">{closeLabel}</span>
      </button>
    )}
    {children}
  </div>
);

export { Alert, AlertType };
